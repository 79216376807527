import { Divider, IconButton, makeStyles, Snackbar, SwipeableDrawer } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import React, { useCallback, useEffect, useState } from 'react'
import { useCart } from '../functions/CartContext'
import ItemCard from './ItemCard'

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 450,
    maxWidth: '90vw',
    padding: '1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto'
  }
}))

export default function ProductDrawer({ product, isOpen, onClose }) {
  const classes = useStyles()
  const { cartDispatch, createTempItem } = useCart()

  const [tempItem, setTempItem] = useState(null)
  function onChangeQuantity(quantity) {
    setTempItem({ ...tempItem, quantity })
  }
  function onChangeMethod(method) {
    setTempItem({ ...tempItem, method })
  }
  function onChangeMeasure(measure) {
    setTempItem({ ...tempItem, measure })
  }
  function onChangeUserNote(userNote) {
    setTempItem({ ...tempItem, userNote })
  }

  const resetTempItem = useCallback(
    () => {
      if (product) {
        const tempItem = createTempItem(product)
        setTempItem(tempItem)
      } else {
        setTempItem(null)
      }
    },
    [product, createTempItem],
  )

  // Reset temp item on load
  useEffect(() => {
    resetTempItem()
  }, [resetTempItem])

  const [snackbarState, setSnackbarState] = useState({ isOpen: false, message: '', severity: 'info' })
  function closeSnackbar() {
    setSnackbarState({ isOpen: false, message: '', severity: 'info' })
  }

  function onSubmit() {
    cartDispatch({
      type: 'addItem',
      payload: { tempItem }
    })

    setSnackbarState({
      isOpen: true,
      message: 'Producto añadido al carrito',
      severity: 'success'
    })

    onClose()
    resetTempItem()
  }

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        disableSwipeToOpen={true}
        onOpen={() => { }}
        disableBackdropTransition={!iOS}
        disableDiscovery={true}
      >
        <div>
          <IconButton onClick={onClose}>
            <ChevronRight />
          </IconButton>
          <Divider />
        </div>
        <div className={classes.cardContainer}>
          <ItemCard
            item={tempItem}
            actionType="add"
            onSubmitAction={onSubmit}
            onChangeMeasure={onChangeMeasure}
            onChangeMethod={onChangeMethod}
            onChangeQuantity={onChangeQuantity}
            onChangeUserNote={onChangeUserNote}
          />
        </div>
      </SwipeableDrawer>

      <Snackbar
        open={snackbarState.isOpen}
        autoHideDuration={5000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarState.severity} variant="filled">
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </>
  )
}
