import React from 'react'
import { makeStyles, Link, Paper } from '@material-ui/core'
import logo from '../assets/images/logo-povill.jpg'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    padding: '3px 30px',
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: '0 0 2rem 0'
  },
  logoImg: {
    height: 55
  }
}))

export default function Logo() {
  const classes = useStyles()

  return (
    <Paper className={classes.logoContainer}>
      <Link component={RouterLink} to="/">
        <img src={logo} alt="logo Peixateria Povill" className={classes.logoImg} />
      </Link>
    </Paper>
  )
}
