import React from 'react'
import PrimaryAppBar from '../components/PrimaryAppBar'
import MainContainer from '../components/MainContainer'
import Footer from '../components/Footer'
import { makeStyles, Typography, Link } from '@material-ui/core'
import legal0 from '../assets/images/legal_0.jpg'
import legal1 from '../assets/images/legal_1.jpg'
import { Link as RouterLink } from 'react-router-dom'
import useDocumentTitle from '../functions/useDocumentTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff'
  },
  title: {
    fontSize: '40px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      textAlign: 'center'
    }
  }
}))

export default function Legal() {
  useDocumentTitle('Povill: Legal')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PrimaryAppBar />
      <MainContainer>
        <Typography
          variant="h4"
          className={classes.title}
        >
          Aviso legal
        </Typography>
        <div>
          <h2>Titularidad</h2>
          <p>En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, se hace constar que esta página corresponde a la persona jurídica MARTA POVILL I CLARET, cuyos datos identificativos son:</p>
          <ul>
            <li>Titular: MARTA POVILL I CLARET </li>
            <li>Denominación fiscal: MARTA POVILL I CLARET </li>
            <li>Nombre comercial (marca): PEIXATERIA POVILL </li>
            <li>Domicilio social: c/Barcelona 2, 08242 Manresa, Barcelona </li>
            <li>Correo electrónico: <Link color="textSecondary" href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>{process.env.REACT_APP_CONTACT_MAIL}</Link></li>
            <li>Sitio web: <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link></li>
          </ul>
          <h2>Finalidad</h2>
          <p>La finalidad del sitio web <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link> es informar y promocionar la venta pescados y mariscos.</p>
          <h2>Condiciones de uso</h2>
          <p>La utilización del sitio web otorga la condición de Usuario, e implica la aceptación completa de todas las cláusulas y condiciones de uso incluidas en las páginas:</p>
          <ul>
            <li>Aviso legal (esta sección) </li>
            <li><Link color="textSecondary" component={RouterLink} to="/cookies">Política de cookies</Link></li>
            <li><Link color="textSecondary" component={RouterLink} to="/privacy">Política de privacidad</Link></li>
          </ul>
          <p>Si el usuario no estuviera conforme con todas y cada una de estas cláusulas y condiciones, debe abstenerse de utilizar este sitio web.</p>
          <p>El acceso a este sitio web no supone, en modo alguno, el inicio de una relación comercial con el titular.</p>
          <p>A través de este sitio web, el Titular (en adelante, PEIXATERIAPOVILL) facilita al usuario el acceso y la utilización de diversos contenidos que PEIXATERIAPOVILL o sus colaboradores han publicado en internet.</p>
          <p>A tal efecto, el usuario está obligado y comprometido a NO utilizar cualquiera de los contenidos del sitio web con fines o efectos ilícitos, prohibidos en este Aviso Legal o por la legislación vigente, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de los contenidos, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático propios o contratados por PEIXATERIAPOVILL, de otros usuarios o de cualquier usuario de internet.</p>
          <h2>Medidas de seguridad</h2>
          <p>Los datos personales que el usuario facilite a PEIXATERIAPOVILL pueden ser almacenados en bases de datos automatizadas o no, cuya titularidad corresponde en exclusiva a PEIXATERIAPOVILL, que asume todas las medidas de índole técnica, organizativa y de seguridad que garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas, de acuerdo con lo establecido en la normativa vigente en protección de datos.</p>
          <p>No obstante, el usuario debe ser consciente de que las medidas de seguridad de los sistemas informáticos en internet no son enteramente fiables y que, por tanto, PEIXATERIAPOVILL no puede garantizar la inexistencia de virus u otros elementos que puedan producir alteraciones en los sistemas informáticos (software y hardware) del usuario o en sus documentos electrónicos y ficheros contenidos en los mismos, aunque PEIXATERIAPOVILL pone todos los medios necesarios y toma las medidas de seguridad oportunas para evitar la presencia de estos elementos dañinos.</p>
          <h2>Datos personales</h2>
          <p>El usuario puede consultar toda la información relativa al tratamiento de datos personales que recoge PEIXATERIAPOVILL en la página de la Política de privacidad.</p>
          <h2>Contenidos</h2>
          <p>PEIXATERIAPOVILL ha obtenido la información, el contenido multimedia y los materiales incluidos en el sitio web de fuentes que considera fiables, pero, si bien ha tomado todas las medidas razonables para asegurar que la información contenida es correcta, PEIXATERIAPOVILL no garantiza que sea exacta, completa o actualizada. PEIXATERIAPOVILL declina expresamente cualquier responsabilidad por error u omisión en la información contenida en las páginas de este sitio web.</p>
          <p>Queda prohibido transmitir o enviar a través del sitio web cualquier contenido ilegal o ilícito, virus informáticos o mensajes que, en general, afecten o violen derechos de PEIXATERIAPOVILL o de terceros.</p>
          <p>Los contenidos del sitio web tienen una finalidad informativa y comercial. </p>
          <p>PEIXATERIAPOVILL se reserva el derecho a modificar, suspender, cancelar o restringir el contenido del sitio web, los vínculos o la información obtenida a través del sitio web, sin necesidad de previo aviso.</p>
          <p>PEIXATERIAPOVILL no es responsable de los daños y perjuicios que pudieran derivarse de la utilización de la información del sitio web o de la información contenida en las redes sociales de PEIXATERIAPOVILL.</p>
          <h2>Política de cookies</h2>
          <p>En la página Política de cookies el usuario puede consultar toda la información relativa a la política de recogida y tratamiento de las cookies.</p>
          <p>PEIXATERIAPOVILL solo obtiene y conserva la siguiente información acerca de los visitantes del sitio web:</p>
          <ul>
            <li>El nombre de dominio del proveedor (PSI) y/o dirección IP que les da acceso a la red.</li>
            <li>La fecha y hora de acceso al sitio web.</li>
            <li>La dirección de internet origen del enlace que dirige al sitio web.</li>
            <li>El número de visitantes diarios de cada sección.</li>
          </ul>
          <p>La información obtenida es totalmente anónima, y en ningún caso puede ser asociada a un usuario concreto e identificado.</p>
          <h2>Enlace a otros sitios web </h2>
          <p>El establecimiento del enlace no implica necesariamente la existencia de relaciones entre PEIXATERIAPOVILL y el sitio en el que se establezca el enlace, ni la aceptación o aprobación por parte del PEIXATERIAPOVILL de sus contenidos o servicios.</p>
          <p>Si el usuario accede a un sitio web externo desde un enlace que encuentre en el sitio web de PEIXATERIAPOVILL, deberá leer la política de privacidad propia del otro sitio web, que puede ser diferente de la de este sitio web.</p>
          <h2>Otras funcionalidades dentro de la misma web</h2>
          <p>PEIXATERIAPOVILL proporciona al usuario acceso al registro para iniciar una sesión única y exclusiva para cada usuario, con lo que requiere su registro, y sus datos personales.</p>
          <p><Link color="textSecondary" component={RouterLink} to="/login">{`${process.env.REACT_APP_BASE_URL}/login`}</Link></p>
          <p>El usuario, al registrarse para realizar la compra online, es donde podrá adquirir los productos de la web. </p>
          <p>La seguridad de los datos está garantizada, pues se generan los certificados para poder establecer conexiones seguras mediante el protocolo HTTPS (Hypertext Transfer Protocol Secure en inglés y Protocolo seguro de transferencia de hipertexto en castellano, que es un protocolo que permite establecer una conexión segura entre el servidor y el cliente (dispositivo móvil o PC, que no puede ser interceptada por personas no autorizadas). Un protocolo de comunicación de Internet que protege la integridad y la confidencialidad de los datos de los usuarios entre sus dispositivos y el sitio web. </p>
          <p>Los servicios de terceros utilizados son:</p>
          <ul>
            <li>Para la gestión de los usuarios, la web utiliza el software “Firebase” de la empresa Google. Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos. En Firebase, cuya política de privacidad se puede leer en <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://policies.google.com/privacy">https://policies.google.com/privacy</Link> se guarda la información en la nube de las nuevas altas de usuarios de forma segura, que se registran de las dos maneras explicadas a continuación. </li>
          </ul>
          <p>Los usuarios se pueden registrar de las siguientes dos maneras: i) mediante nuevo registro vía web, creando el alta en la base de datos de Firebase, donde el usuario escoge un nombre de usuario (o apodo o mote) y una contraseña,</p>
          <img src={legal0} width="720" style={{ maxWidth: '100%' }} alt="Crear cuenta" />
          <p>o bien ii) el usuario directamente puede iniciar sesión, mediante su cuenta privada de Google.</p>
          <img src={legal1} width="720" style={{ maxWidth: '100%' }} alt="Iniciar sesión" />
          <p>Donde el usuario debe introducir su correo de Google Gmail, que utiliza para otros servicios de la mencionada empresa. Después de cada alta, el usuario debe confirmar su dirección de correo electrónico asociado al alta, mediante un clic en un enlace único, para verificar que su dirección de correo electrónico es válida y no se trata de un usuario falso, bot o similar.</p>
          <ul>
            <li>La gestión pedidos: la web utiliza la solución de Ugiat Technologies SL, cuya política de privacidad se puede leer en <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://www.ugiat.com/">https://www.ugiat.com/</Link>, y se guardan en sus servidores localizados físicamente en Ugiat Technologies SL (CIF: B66452673) c/Sant Jordi, 3, 08100 Mollet del Vallès. En dicha gestión, se guardan los siguientes tres campos de datos: nombre elegido por el usuario (“Nickname, o nombre apodo o mote), el teléfono y la lista de los productos que ha comprado. </li>
            <li>La gestión de pagos: la web utiliza la solución de la empresa Stripe (localizada en 510 Townsend Street San Francisco, CA 94103, Estados Unidos). Stripe, con su propia política de privacidad que se puede leer en <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://stripe.com/es/privacy">https://stripe.com/es/privacy</Link>, está especializada en sistemas de pagos online, como pasarela de pago en la nube, que también permite guardar el número de la tarjeta VISA, fecha de caducidad y código CVC de la misma, para que se pueda reutilizar la VISA en compras siguientes. Stripe es utilizado por millones de empresas de todos los tamaños, desde startups hasta grandes empresas, que usan el software y las API (Interfaz de Programación de la Aplicación) de Stripe para aceptar pagos, enviar transferencias y gestionar sus actividades comerciales en Internet, y ayudar contra el fraude de internet. </li>
          </ul>
          <h2>Propiedad intelectual e industrial</h2>
          <p>Todos los derechos están reservados.</p>
          <p>Todo acceso a este sitio web está sujeto a las siguientes condiciones: la reproducción, almacenaje permanente y la difusión de los contenidos o cualquier otro uso que tenga finalidad pública o comercial queda expresamente prohibida sin el consentimiento previo expreso y por escrito de PEIXATERIAPOVILL.</p>
          <h2>Limitación de responsabilidad</h2>
          <p>La información y servicios incluidos o disponibles a través de este sitio web pueden incluir incorrecciones o errores tipográficos. De forma periódica, PEIXATERIAPOVILL incorpora mejoras y/o cambios a la información contenida y/o los productos y servicios que puede introducir en cualquier momento.</p>
          <p>PEIXATERIAPOVILL no declara ni garantiza que los servicios o contenidos sean interrumpidos o que estén libres de errores, que los defectos sean corregidos, o que el servicio o el servidor que lo pone a disposición estén libres de virus u otros componentes nocivos sin perjuicio de que PEIXATERIAPOVILL realiza todos los esfuerzos para evitar este tipo de incidentes.</p>
          <p>PEIXATERIAPOVILL declina cualquier responsabilidad en caso de que existan interrupciones o un mal funcionamiento de los servicios o contenidos ofrecidos en internet, cualquiera que sea su causa. Asimismo, PEIXATERIAPOVILL no se hace responsable por caídas de la red, pérdidas de pedidos a consecuencia de dichas caídas, suspensiones temporales de fluido eléctrico o cualquier otro tipo de daño indirecto que pueda ser causado al usuario por causas ajenas a PEIXATERIAPOVILL.</p>
          <p>Antes de tomar decisiones y/o acciones basadas en la información incluida en el sitio web, PEIXATERIAPOVILL recomienda comprobar y contrastar la información recibida con otras fuentes.</p>
          <h2>Jurisdicción</h2>
          <p>Este Aviso legal se rige íntegramente por la legislación española.</p>
          <p>Siempre que no haya una norma que obligue a otra cosa, para cuantas cuestiones se susciten sobre la interpretación, aplicación y cumplimiento de este Aviso Legal, así como de las reclamaciones que puedan derivarse de su uso, las partes acuerdan someterse a los Jueces y Tribunales de la provincia de BARCELONA, con renuncia expresa de cualquier otra jurisdicción que pudiera corresponderles.</p>
          <h2>Contacto</h2>
          <p>En caso de que el usuario tenga cualquier duda acerca de estas condiciones legales o quiera realizar cualquier comentario sobre este sitio web, puede enviar un mensaje de correo electrónico a la dirección <Link color="textSecondary" href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>{process.env.REACT_APP_CONTACT_MAIL}</Link></p>
          <p><i>Última actualización de este Aviso legal: <b>diciembre 2020.</b></i></p>
        </div>
      </MainContainer>
      <Footer />
    </div>
  )
}
