import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../functions/AuthContext'
import { useStoreSchedule } from '../functions/StoreScheduleContext'
import ClosedStore from './ClosedStore'

export default function OpenStoreAndPrivateRoute({ component: Component, ...props }) {
  const storeSchedule = useStoreSchedule()
  const { user } = useAuth()

  return (
    <Route
      {...props}
      render={({ location }) => (
        storeSchedule === null ?
          (
            <Backdrop
              open={true}
            >
              <CircularProgress color="secondary" />
            </Backdrop>
          ) : (
            storeSchedule.isOpen ?
              user && user.emailVerified ?
                <Component />
                :
                <Redirect to={{ pathname: "/login", state: { from: location } }} />
              :
              <ClosedStore storeSchedule={storeSchedule} />
          )
      )
      }
    />
  )
}
