import * as React from "react";

function SvgLmAccount(props) {
  return (
    <svg
      id="LM_Account_svg__Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.62 28.01"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>{".LM_Account_svg__cls-1{fill:#7d8123}"}</style>
      </defs>
      <path
        className="LM_Account_svg__cls-1"
        d="M12.31 14.21a7.11 7.11 0 117.11-7.1 7.11 7.11 0 01-7.11 7.1zm0-13.21a6.11 6.11 0 106.11 6.11A6.12 6.12 0 0012.31 1z"
      />
      <path
        className="LM_Account_svg__cls-1"
        d="M24.34 28H.29l-.08-.4a12.31 12.31 0 1124.2 0zM1.13 27H23.5a11.49 11.49 0 00.12-1.63A11.31 11.31 0 001 25.38 10.22 10.22 0 001.13 27z"
      />
    </svg>
  );
}

export default SvgLmAccount;
