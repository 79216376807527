import React, { useEffect, useState } from 'react'
import { Snackbar, Button, IconButton, Link } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useLocalStorage from '../functions/useLocalStorage'
import { Link as RouterLink, useLocation } from 'react-router-dom'

export default function CookiesMessage() {
  const [open, setOpen] = useState(false)
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage('cookiesAccepted', false)
  const { pathname } = useLocation()

  // Check cookies if cookiesAccepted state or pathname changed
  useEffect(() => {
    if (!cookiesAccepted) {
      setOpen(true)
    }
  }, [cookiesAccepted, pathname])

  function onClickAccept() {
    setCookiesAccepted(true)
    setOpen(false)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      message={
        <span>
          Esta web utiliza cookies para mejorar y personalizar tu experiencia.<br />
          <Link component={RouterLink} to="/cookies">Mas información</Link>
        </span>
      }
      action={
        <>
          <Button
            color="secondary"
            size="small"
            onClick={onClickAccept}
          >
            Aceptar cookies
          </Button>
          <IconButton
            size="small"
            aria-label="cerrar"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  )
}
