import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { makeStyles, Fab, Badge } from '@material-ui/core'
import { ShoppingCart, List } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import { useCart } from '../functions/CartContext'
import OpenSeadragon from 'openseadragon'
import '../openseadragon/openseadragon-svg-overlay'
import './CamerasViewer_SVG.css'
import PullNav from '../components/PullNav'
import ProductDrawer from '../components/ProductDrawer'
import ProductsListDrawer from '../components/ProductsListDrawer'
import useDocumentTitle from '../functions/useDocumentTitle'
import LogoMain from '../components/LogoMain'
import AccountButton from '../components/AccountButton'
import CameraStepper from '../components/CameraStepper'

const cameraUpdateInterval = 0.5 * 60 * 1000
const pullThreshold = 130
const productIconSize = 0.05

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100%',
    backgroundColor: '#222',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    position: 'relative'
  },
  openseadragon: {
    width: '100vw',
    height: '100%'
  },
  searchFab: {
    position: 'absolute',
    top: 'calc(50vh - 28px)',
    right: theme.spacing(2)
  },
  cartFab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}))

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

export default function CamerasViewer() {
  useDocumentTitle('Povill: Visor')

  const classes = useStyles()

  const [storeCams, setStoreCams] = useState([])

  function updateCameras() {
    // Fetch store cameras
    fetch(`/api/cameras/povill`)
      .then(res => res.json())
      .then(jsonRes => {
        if ('error' in jsonRes) {
          console.log(jsonRes.error.message)
        } else {
          setStoreCams(jsonRes)
          console.log('cameras updated')
        }
      })
  }

  // Update cameras on a regular interval
  useEffect(() => {
    updateCameras()
    const inter = setInterval(() => {
      updateCameras()
    }, cameraUpdateInterval)

    return () => {
      clearInterval(inter)
    }
  }, [])

  const [isProductDrawerOpen, setIsProductDrawerOpen] = useState(false)
  const openProductDrawer = () => setIsProductDrawerOpen(true)
  const closeProductDrawer = () => setIsProductDrawerOpen(false)

  const [isListDrawerOpen, setIsListDrawerOpen] = useState(false)
  const openListDrawer = () => setIsListDrawerOpen(true)
  const closeListDrawer = () => setIsListDrawerOpen(false)

  const { numItems } = useCart()

  // Use session storage to persist page
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('camerasViewerPage')) || 0
  )
  useEffect(() => {
    if (viewer.current) {
      viewer.current.goToPage(page)
      sessionStorage.setItem('camerasViewerPage', page.toString())
    }
  }, [page])

  // OpenSeadragon viewer
  const viewer = useRef(null)
  const viewerSvgOverlay = useRef(null)

  // Create OpenSeadragon viewer
  useEffect(() => {
    // Create OpenSeadragon viewer
    viewer.current = new OpenSeadragon.Viewer({
      id: 'openseadragon',
      prefixUrl: `${process.env.PUBLIC_URL}/openseadragon_ui_flat/`,
      tileSources: [],
      showFullPageControl: false,
      autoHideControls: false,
      navigationControlAnchor: OpenSeadragon.ControlAnchor.BOTTOM_LEFT,
      sequenceMode: true,
      sequenceControlAnchor: OpenSeadragon.ControlAnchor.BOTTOM_LEFT,
      showSequenceControl: false,
      // debugMode: true,
      homeFillsViewer: true,
      // defaultZoomLevel: 1,
      minZoomLevel: 0.6,
      // maxZoomLevel: null,
      visibilityRatio: 1.0,
      // minZoomImageRatio: 0.9,
      constrainDuringPan: true,
      showNavigationControl: false,
      gestureSettingsMouse: {
        clickToZoom: false
      },
      gestureSettingsTouch: {
        clickToZoom: false
      },
      iOSDevice: iOS,
      preserveViewport: true,
      placeholderFillStyle: '#333333'
    })

    console.log('viewer created')

    // Create svg overlay
    viewerSvgOverlay.current = viewer.current.svgOverlay()
  }, [])

  useEffect(() => {
    let timeoutId = null
    function onOrientationChange() {
      timeoutId = setTimeout(() => {
        viewer.current.viewport.goHome()
      }, 50)
    }

    window.addEventListener('orientationchange', onOrientationChange)

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
      window.addEventListener('orientationchange', onOrientationChange)
    }
  }, [])

  const [isRestoreViewportOnOpen, setIsRestoreViewportOnOpen] = useState(null)
  useEffect(() => {
    function onPageChange() {
      setIsRestoreViewportOnOpen(true)
      setPage(viewer.current.currentPage())
      updateCameras()
    }

    viewer.current.addHandler('page', onPageChange)

    return () => {
      viewer.current.removeHandler('page', onPageChange)
    }
  }, [])

  // Products for the list
  const [listProducts, setListProducts] = useState([])
  // Load products from a specific camera or all if "all" is passed 
  function loadListProducts(cameraId) {
    setListProducts(null)
    fetch(`/api/products/povill?on_camera=${cameraId}`)
      .then(res => res.json())
      .then(resJson => {
        if (resJson.error) {
          setListProducts([])
        } else {
          setListProducts(resJson)
        }
      })
  }

  // Handle camera list
  useEffect(() => {
    let tiemoutId = null
    function onCanvasClick(event) {
      // Only trigger if click (quick = true) not on drag (quick = false)
      if (event.quick) {
        tiemoutId = setTimeout(() => {
          setOnlyImageListChecked(true)
          loadListProducts(storeCams[page]._id)
          openListDrawer()
        }, 50)
      }
    }

    viewer.current.addHandler('canvas-click', onCanvasClick)

    return () => {
      if (tiemoutId) clearTimeout(tiemoutId)
      viewer.current.removeHandler('canvas-click', onCanvasClick)
    }
  }, [storeCams, page])

  // Products list
  const [onlyImageListChecked, setOnlyImageListChecked] = useState(true)

  function onClickListFab() {
    setOnlyImageListChecked(false)
    loadListProducts('all')
    openListDrawer()
  }

  function toggleOnlyImageListChecked() {
    const newOnlyImageListChecked = !onlyImageListChecked
    setOnlyImageListChecked(newOnlyImageListChecked)
    if (newOnlyImageListChecked) {
      loadListProducts(storeCams[page]._id)
    } else {
      loadListProducts('all')
    }
  }

  const addToPage = useCallback(
    (n) => {
      // Apply modulo to cycle through pages
      setPage((((page + n) % storeCams.length) + storeCams.length) % storeCams.length)
    },
    [page, storeCams.length],
  )

  const [dragStartX, setDragStartX] = useState(0)
  const [deltaX, setDeltaX] = useState(0)
  const [isPullLeftActive, setIsPullLeftActive] = useState(false)
  const [isPullRightActive, setIsPullRightActive] = useState(false)

  useEffect(() => {
    function onCanvasDrag(event) {
      const bds = viewer.current.viewport.getBounds()

      // Relative x position to drag start (deltaX)
      const dx = event.position.x - dragStartX

      // Left and right bound contacts
      const isLeftContact = bds.x < 0.01
      const isRightContact = bds.x + bds.width > 0.99

      if (isLeftContact || isRightContact) {
        // Set drag start on first contact
        if (!dragStartX) {
          setDragStartX(event.position.x)
        } else if (dx > pullThreshold) {
          // If deltaX exceeds pullThreshold move dragStartX to keep the same relative position to threshold
          setDragStartX(event.position.x - pullThreshold)
        } else if (dx < -pullThreshold) {
          // If deltaX exceeds pullThreshold move dragStartX to keep the same relative position to threshold
          setDragStartX(event.position.x + pullThreshold)
        }

        setIsPullLeftActive(isLeftContact)
        setIsPullRightActive(isRightContact)
      }

      if (dragStartX) setDeltaX(dx)
    }

    viewer.current.addHandler('canvas-drag', onCanvasDrag)

    return () => {
      viewer.current.removeHandler('canvas-drag', onCanvasDrag)
    }
  }, [dragStartX])

  const pullLeft = useMemo(() =>
    (isPullLeftActive && deltaX > 0.01) ? Math.min(1, deltaX / pullThreshold) : 0
    , [deltaX, isPullLeftActive])
  const pullRight = useMemo(() =>
    (isPullRightActive && deltaX < 0.01) ? Math.min(1, -deltaX / pullThreshold) : 0
    , [deltaX, isPullRightActive])

  useEffect(() => {
    function onCanvasDragEnd() {
      if (pullLeft > 0.999) {
        addToPage(-1)
      } else if (pullRight > 0.999) {
        addToPage(1)
      }

      // Reset pull data
      setDragStartX(0)
      setDeltaX(0)
      setIsPullLeftActive(false)
      setIsPullRightActive(false)
    }

    viewer.current.addHandler('canvas-drag-end', onCanvasDragEnd)

    return () => {
      viewer.current.removeHandler('canvas-drag-end', onCanvasDragEnd)
    }
  }, [addToPage, pullLeft, pullRight])

  const [selectedProduct, setSelectedProduct] = useState(null)

  const loadSelectedProduct = useCallback(
    (productId) => {
      // If product is already loaded dont do anythig 
      if (selectedProduct?._id !== productId) {
        setSelectedProduct(null)
        fetch(`/api/products/povill/${productId}`)
          .then(res => res.json())
          .then(data => {
            if (data.error) {
              setSelectedProduct(null)
            } else {
              setSelectedProduct(data)
            }
          })
      }
    },
    [selectedProduct]
  )

  // On viewer open add svg overlays to click on products and restore previous 
  // viewer settings if any
  useEffect(() => {
    let timeoutId = null
    function loadCurrentPageLabels() {
      const currentCam = storeCams[page]

      if (!currentCam) return

      // Remove previous labels
      let overlayG = viewerSvgOverlay.current.node()
      while (overlayG.firstChild) {
        overlayG.removeChild(overlayG.firstChild)
      }

      // Add current page labels
      currentCam.image.labels.forEach(label => {
        let useMarker = document.createElementNS('http://www.w3.org/2000/svg', 'use')
        useMarker.setAttribute('href', '#product-marker')
        useMarker.setAttribute('width', productIconSize)
        useMarker.setAttribute('height', productIconSize)
        useMarker.setAttribute('x', label.osdIconPoint.x - 0.5 * productIconSize)
        useMarker.setAttribute('y', label.osdIconPoint.y - productIconSize)
        useMarker.setAttribute('class', 'product-marker')

        viewerSvgOverlay.current.node().appendChild(useMarker)

        viewerSvgOverlay.current.onClick(useMarker, function () {
          loadSelectedProduct(label.productId)
          timeoutId = setTimeout(() => {
            openProductDrawer()
          }, 50)
        })
      })
    }

    function restoreViewportSettings() {
      if (isRestoreViewportOnOpen) {
        viewer.current.viewport.goHome(true)
        setIsRestoreViewportOnOpen(false)
      }
    }

    function onViewerOpen() {
      restoreViewportSettings()
      loadCurrentPageLabels()
    }

    viewer.current.addHandler('open', onViewerOpen)
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
      viewer.current.removeHandler('open', onViewerOpen)
    }
  }, [storeCams, isRestoreViewportOnOpen, page, loadSelectedProduct])

  function onClickListProduct(productId) {
    loadSelectedProduct(productId)
    openProductDrawer()
  }

  // Open tilesources if storeCams change
  useEffect(() => {
    const tileSources = storeCams.map(cam => `/api/cameras/povill/dzi/${cam.image.fileNameDzi}`)

    // Open new tilesources
    viewer.current.open(tileSources, page)

    console.log('opened tilesources')
  }, [storeCams, page])


  return (
    <div className={classes.root}>
      <LogoMain />

      <AccountButton />

      <svg style={{ display: 'none' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.64 67.39">
        <symbol id="product-marker" width="49.64" height="67.39" viewBox="0 0 49.64 67.39">
          <path fill="inherit" d="M24.82,67.39a1.12,1.12,0,0,1-.74-.28A98,98,0,0,1,12.08,54C4.06,43.48,0,33.67,0,24.82a24.82,24.82,0,0,1,49.64,0c0,8.85-4.06,18.66-12.07,29.18a98.11,98.11,0,0,1-12,13.11,1.12,1.12,0,0,1-.74.28Z" />
          <path fill="#fff" d="M37.32,21.61a.82.82,0,0,0-.66-.33h-5a5.7,5.7,0,0,0-11.28,0H16.15l-1.06-7.49a.82.82,0,0,0-.8-.7H10.51a.81.81,0,0,0-.81.81.82.82,0,0,0,.81.82h3.07l2.28,16.15a4.17,4.17,0,0,0,1.29,2.42,2.44,2.44,0,0,0,.91,4.24,2.44,2.44,0,1,0,4.71.9,2.38,2.38,0,0,0-.14-.81h6.8a2.16,2.16,0,0,0-.14.81A2.44,2.44,0,1,0,31.73,36h-13a.82.82,0,0,1,0-1.63H30.9a4.07,4.07,0,0,0,3.91-2.95l2.64-9.09A.82.82,0,0,0,37.32,21.61Zm-5.59,16a.81.81,0,0,1,0,1.62.81.81,0,1,1,0-1.62Zm-11.4,0a.81.81,0,1,1,0,1.62.81.81,0,0,1,0-1.62ZM26,18A4.07,4.07,0,1,1,22,22.1,4.07,4.07,0,0,1,26,18ZM33.24,31a2.42,2.42,0,0,1-2.34,1.76h-11a2.45,2.45,0,0,1-2.42-2.1l-1.08-7.72h4a5.7,5.7,0,0,0,11.28,0h3.91Z" />
          <path fill="#fff" d="M24,22.51h1.45V24a.21.21,0,0,0,.2.21h.77a.21.21,0,0,0,.21-.21V22.51h1.44a.21.21,0,0,0,.21-.21v-.74a.2.2,0,0,0-.21-.2H26.63V19.84a.2.2,0,0,0-.21-.2h-.77a.2.2,0,0,0-.2.2v1.52H24a.2.2,0,0,0-.21.2v.74A.21.21,0,0,0,24,22.51Z" />
        </symbol>
      </svg>

      <div id="openseadragon" className={classes.openseadragon}></div>

      <PullNav pullLeft={pullLeft} pullRight={pullRight} />

      <CameraStepper
        steps={storeCams.length}
        activeStep={page}
        onClickBack={() => addToPage(-1)}
        onClickNext={() => addToPage(1)}
      />

      <ProductDrawer
        product={selectedProduct}
        isOpen={isProductDrawerOpen}
        onClose={closeProductDrawer}
      />

      <ProductsListDrawer
        products={listProducts}
        isOpen={isListDrawerOpen}
        onlyImageChecked={onlyImageListChecked}
        toggleOnlyImageChecked={toggleOnlyImageListChecked}
        onClose={closeListDrawer}
        onClickProduct={onClickListProduct}
      />

      <Fab
        color="default"
        aria-label="search"
        className={classes.searchFab}
        onClick={onClickListFab}
      >
        <List />
      </Fab>

      <Fab
        color="secondary"
        aria-label="go to cart"
        className={classes.cartFab}
        component={RouterLink}
        to="/cart"
      >
        <Badge badgeContent={numItems} color="error">
          <ShoppingCart />
        </Badge>
      </Fab>
    </div >
  )
}
