import React, { useState, useEffect, useContext, createContext } from 'react'

const StoreScheduleContext = createContext()

function useStoreSchedule() {
  return useContext(StoreScheduleContext)
}

function StoreScheduleProvider({ children }) {
  const [storeSchedule, setStoreSchedule] = useState(null)

  useEffect(() => {
    fetch('/api/stores/povill/schedule')
      .then(res => res.json())
      .then(jsonRes => {
        if ('error' in jsonRes) {
          setStoreSchedule(null)
        } else {
          setStoreSchedule(jsonRes)
        }
      })
  }, [])

  return (
    <StoreScheduleContext.Provider value={storeSchedule}>
      {children}
    </StoreScheduleContext.Provider>
  )
}

export { StoreScheduleProvider, useStoreSchedule }