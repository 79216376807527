import { Card, CardContent, CardMedia, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import povill_peix from '../assets/images/povill_peix.jpg'
import LogoMain from '../components/LogoMain'
import useDocumentTitle from '../functions/useDocumentTitle'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  cardContainer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  card: {
    width: '100%',
  },
  media: {
    height: 200
  },
  textContainer: {
    paddingBottom: '0.5rem'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

export default function ClosedStore({ storeSchedule }) {
  useDocumentTitle('Povill: Cerrado')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LogoMain />

      <Container
        className={classes.cardContainer}
        maxWidth="md"
      >
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={povill_peix}
            title="Mostrador de la pescaderia Povill"
          />
          <CardContent>
            <div className={classes.textContainer}>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                gutterBottom
              >
                Peixateria Povill
              </Typography>
              <Typography
                variant="body1"
                align="center"
              >
                La tienda está cerrada.
              </Typography>
              <Typography
                variant="body1"
                align="center"
                gutterBottom
              >
                Lamentamos no poder atenderle en este momento.
              </Typography>
            </div>

            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  align="center"
                  gutterBottom
                >
                  El horario de atención al público es:
                </Typography>
              </Grid>

              {
                storeSchedule.weeklyTimeSchedule.map(({ dayName, intervals }) =>
                  <Grid item container key={dayName} spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        align="right"
                        color="textSecondary"
                      >
                        {dayName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {
                        intervals.map(interval =>
                          <Typography
                            variant="body1"
                            key={interval}
                          >
                            {interval}
                          </Typography>
                        )
                      }
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
