import { Drawer, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useCart } from '../functions/CartContext'
import ItemCard from './ItemCard'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 450,
    maxWidth: '90vw',
    padding: '1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto'
  }
}))

export default function CartItemDrawer({ item, isOpen, onClose }) {
  const classes = useStyles()
  const { cartDispatch, createTempItem } = useCart()

  const [tempItem, setTempItem] = useState(null)

  const resetTempItem = useCallback(
    () => {
      if (item) {
        setTempItem(createTempItem(item.product, item.quantity, item.method, item.measure, item.userNote))
      } else {
        setTempItem(null)
      }
    },
    [item, createTempItem],
  )

  useEffect(resetTempItem, [resetTempItem])

  function onChangeQuantity(quantity) {
    setTempItem({ ...tempItem, quantity })
  }
  function onChangeMethod(method) {
    setTempItem({ ...tempItem, method })
  }
  function onChangeMeasure(measure) {
    setTempItem({ ...tempItem, measure })
  }
  function onChangeUserNote(userNote) {
    setTempItem({ ...tempItem, userNote })
  }

  function onSubmitEdit() {
    cartDispatch({
      type: 'changeItem',
      payload: {
        item: item,
        method: tempItem.method,
        measure: tempItem.measure,
        quantity: tempItem.quantity,
        userNote: tempItem.userNote
      }
    })
    onClose()
  }
  function onCancelEdit() {
    resetTempItem()
    onClose()
  }

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={isOpen}
    >
      <div className={classes.cardContainer}>
        <ItemCard
          item={tempItem}
          actionType="cancel-save"
          onSubmitAction={onSubmitEdit}
          onClickCancel={onCancelEdit}
          onChangeMeasure={onChangeMeasure}
          onChangeMethod={onChangeMethod}
          onChangeQuantity={onChangeQuantity}
          onChangeUserNote={onChangeUserNote}
        />
      </div>
    </Drawer>
  )
}
