import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 202px)' // 202 = 88 + 114
  },
  container: {
    padding: '2rem 2rem',
    maxWidth: 1500
  }
}))

export default function MainContainer({ children }) {
  const classes = useStyles()

  return (
    <main className={classes.root}>
      <div className={classes.container}>
        {children}
      </div>
    </main>
  )
}
