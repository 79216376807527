import React, { useMemo, useState } from 'react'
import { useCart } from '../functions/CartContext'
import { Grid, Button, makeStyles, Paper, Typography } from '@material-ui/core'
import CartItemCard from '../components/CartItemCard'
import { Link as RouteLink, useHistory } from 'react-router-dom'
import { SentimentDissatisfied } from '@material-ui/icons'
import CartItemDrawer from '../components/CartItemDrawer'
import useDocumentTitle from '../functions/useDocumentTitle'
import LogoMain from '../components/LogoMain'
import AccountButton from '../components/AccountButton'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 800,
    maxWidth: '100vw',
    padding: theme.spacing(3),
    marginTop: 70,
    marginBottom: 65,
    paddingBottom: 40
  },
  cardsContainer: {
    maxWidth: 600
  },
  emptyMessage: {
    margin: '30px 0px 30px 0px'
  },
  totalPrice: {
    fontWeight: 'bold',
    fontSize: '1.5rem'
  }
}))

export default function Cart() {
  useDocumentTitle('Povill: Carrito')

  const classes = useStyles()
  const { cartState, totalPrice, cartDispatch, formatPrice } = useCart()

  const totalStr = formatPrice(totalPrice)

  const [isItemDrawerOpen, setIsItemDrawerOpen] = useState(false)
  const openItemDrawer = () => setIsItemDrawerOpen(true)
  const closeItemDrawer = () => setIsItemDrawerOpen(false)

  function onClickDeleteItem(item) {
    cartDispatch({
      type: 'removeItem',
      payload: { item }
    })
  }

  // Item selected
  const [selectedItem, setSelectedItem] = useState(null)
  function onClickEditItem(item) {
    setSelectedItem(item)
    openItemDrawer()
  }

  // Current cartState.item selected
  const selectedCartItem = useMemo(() =>
    selectedItem && cartState.items.find(cartItem => cartItem.id === selectedItem.id)
    , [cartState.items, selectedItem]
  )

  const history = useHistory()

  function onClickBack() {
    history.goBack()
  }

  return (
    <div>
      <LogoMain />

      <AccountButton />

      <CartItemDrawer
        item={selectedCartItem}
        isOpen={isItemDrawerOpen}
        onClose={closeItemDrawer}
      />

      <Grid container justify="center">
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                Tu carrito
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              spacing={3}
              alignItems="center"
              className={classes.cardsContainer}
              xs={12}
            >
              {
                cartState.items.length === 0 ?
                  <Grid
                    item
                    container
                    justify="center"
                    direction="column"
                    alignItems="stretch"
                    className={classes.emptyMessage}
                  >
                    <Grid item container justify="center">
                      <SentimentDissatisfied />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Tu carrito está vacio.<br />
                        Vuelve atrás para añadir productos.
                      </Typography>
                    </Grid>
                  </Grid>
                  :
                  cartState.items.map(item =>
                    <Grid
                      item
                      xs={12}
                      key={item.id}
                    >
                      <CartItemCard
                        item={item}
                        onClickEdit={() => onClickEditItem(item)}
                        onClickDelete={() => onClickDeleteItem(item)}
                      />
                    </Grid>
                  )
              }

              <Grid item container xs={12} justify="flex-end">
                <Typography
                  variant="h5"
                  align="right"
                  color="textSecondary"
                  className={classes.totalPrice}
                >
                  Total: {totalStr}
                </Typography>
              </Grid>

              {
                totalPrice < 6 &&
                <Grid item xs={12}>
                  <Typography variant="body2" color="error" align="center">
                    La compra mínima es de 6,00 €, te faltan {formatPrice(6 - totalPrice)}.
                  </Typography>
                  <Typography variant="body2" align="center">
                    Puedes editar los productos de tu carrito arriba o volver atrás para añadir otros.
                  </Typography>
                </Grid>
              }

              <Grid item xs={12} container justify="space-between">
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={onClickBack}
                  >
                    Volver
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    component={RouteLink}
                    to="/checkout"
                    variant="contained"
                    color="secondary"
                    disabled={totalPrice < 6}
                  >
                    Comprar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}
