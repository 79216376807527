import { Grid, Hidden, makeStyles, Typography, Link } from '@material-ui/core'
import React from 'react'
import logoVM from '../assets/images/Fresh_blanco_crop_600x115.png'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 88,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.lm.primary.bLight,
    display: 'flex',
    justifyContent: 'center'
  },
  grid: {
    maxWidth: 1500
  },
  logoVM: {
    height: 30
  },
  footerText: {
    fontWeight: 'bold',
    color: '#fff',
    display: 'block',
    letterSpacing: '1.5px'
  },
  footerLink: {
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '1.5px'
  }
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <Grid
        className={classes.grid}
        container
        alignItems="center"
        justify="space-between"
        spacing={2}
      >
        <Hidden smDown>
          <Grid item>
            <Link
              href="https://www.virtualmarket.cloud/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img className={classes.logoVM} src={logoVM} alt="logo Virtual Market" />
            </Link>
          </Grid>
        </Hidden>
        <Grid item xs={12} md>
          <Typography variant="caption" className={classes.footerText} align="center">
            <Link component={RouterLink} to="/privacy" className={classes.footerLink}>POLÍTICA DE PRIVACIDAD</Link>,
            <Link component={RouterLink} to="/legal" className={classes.footerLink}> AVISO LEGAL </Link> Y
            <Link component={RouterLink} to="/cookies" className={classes.footerLink}> COOKIES</Link>
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Typography variant="caption" className={classes.footerText} align="right">
              2020
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
    </footer>
  )
}
