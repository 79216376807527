import {
  Button, Grid, makeStyles, Typography, useMediaQuery, useTheme, Link
} from '@material-ui/core'
import React from 'react'
import PrimaryAppBar from '../components/PrimaryAppBar'
import avatar from '../assets/images/Marta_rec_600x600.jpg'
import povillCam from '../assets/images/povill_cam.jpg'
import vmLogo from '../assets/images/Logo_VM_color_600.png'
import Footer from '../components/Footer'
import LmLocation from '../components/LM-Icons/LmLocation'
import LmArrowRight from '../components/LM-Icons/LmArrowRight'
import { Link as RouterLink } from 'react-router-dom'
import MainContainer from '../components/MainContainer'
import useDocumentTitle from '../functions/useDocumentTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.lm.primary.aLight
  },
  avatarContainer: {
    width: 200,
    height: 200,
    backgroundImage: `url(${avatar})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    display: 'block',
    border: '12px solid #E8E9BB'
  },
  avatarImg: {
    width: '100%'
  },
  camImg: {
    width: '100%'
  },
  welcomeText: {
    fontSize: '25px',
    lineHeight: '31px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 600
  },
  directionText: {
    fontSize: '17px',
    lineHeight: '22px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 600
  },
  directionIcon: {
    color: theme.palette.lm.primary.bMain
  },
  specialButtonContainer: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      height: '100px',
      width: '100%'
    }
  },
  specialButton: {
    marginTop: '2rem',
    lineHeight: '30px',
    color: '#fff',
    backgroundColor: theme.palette.lm.primary.bLight,
    '&:hover': {
      backgroundColor: theme.palette.lm.primary.bMain
    },
    '&:hover $accessArrowContainer': {
      backgroundColor: theme.palette.lm.primary.bLight
    },
    fontWeight: 800,
    fontSize: '18px',
    [theme.breakpoints.up('md')]: {
      fontSize: '21px',
      position: 'absolute',
      width: '111%',
    }
  },
  accessArrowContainer: {
    backgroundColor: theme.palette.lm.secondary.main,
    borderRadius: '50%',
    padding: '0.5rem',
    margin: '-0.5em 0',
    marginLeft: 'auto',
    transform: 'translateX(35px)',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex'
    }
  },
  noteText: {
    fontSize: '16px',
    lineHeight: '23px',
    fontWeight: 600,
    color: theme.palette.lm.primary.aMain,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    }
  },
  poweredByContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  poweredBy: {
    fontSize: '9px',
    lineHeight: '11px',
    fontWeight: 700,
    letterSpacing: '1.5px',
    color: theme.palette.lm.primary.bMain
  },
  vmLogo: {
    margin: '7px',
    width: 150
  }
}))

export default function Home() {
  useDocumentTitle('Povill')

  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={classes.root}>
      <PrimaryAppBar />
      <MainContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} >
            <Grid
              item
              container
              direction="column"
              spacing={3}
              alignItems={matches ? "flex-start" : "center"}
            >
              <Grid item>
                <div className={classes.avatarContainer}></div>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  className={classes.welcomeText}
                  align={matches ? "left" : "center"}
                >
                  Hola! Sóc la Marta Povill i et dono la benviguda a la meva peixateria.
                  </Typography>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                alignItems="center"
                justify={matches ? "flex-start" : "center"}
              >
                <Grid item>
                  <LmLocation width="2em" height="2em" />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" className={classes.directionText}>
                    C/Barcelona, 2 - 08242 <br />
                      Manresa, Barcelona
                    </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                className={classes.specialButtonContainer}
              >
                <Grid item xs>
                  <div className={classes.specialButtonContainer}>
                    <Button
                      className={classes.specialButton}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      component={RouterLink}
                      to="/viewer"
                    >
                      Accede en tiempo real
                        <div className={classes.accessArrowContainer}>
                        <LmArrowRight width="2.3em" height="2.3em" />
                      </div>
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.noteText}>
                  Ver y comprar el producto en tiempo real
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <RouterLink to="/viewer">
              <img className={classes.camImg} src={povillCam} alt="" />
            </RouterLink>
          </Grid>
          <Grid item container xs={12}>
            <div className={classes.poweredByContainer}>
              <Typography variant="body2" align="right" className={classes.poweredBy}>
                POWERED BY
                </Typography>
              <Link
                href="https://www.virtualmarket.cloud/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={vmLogo} alt="Logo VirtualMarket" className={classes.vmLogo} />
              </Link>
            </div>
          </Grid>
        </Grid>
      </MainContainer>
      <Footer />
    </div>
  )
}
