import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar, Toolbar, IconButton, Badge, MenuItem, Menu,
  ButtonGroup, Button, Link
} from '@material-ui/core'
import LmAccount from './LM-Icons/LmAccount'
import LmCart from './LM-Icons/LmCart'
import LmMenu from './LM-Icons/LmMenu'
import logo from '../assets/images/logo-povill.jpg'
import AccountDialog from './AccountDialog'
import { useCart } from '../functions/CartContext'
import { Link as RouterLink, useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: '#fff',
    height: 114,
    justifyContent: 'center',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%',
    maxWidth: 1500
  },
  logoImg: {
    height: 90
  },
  linkButton: {
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 'bold',
    color: theme.palette.lm.text.black,
    padding: '8px 17px'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  highlighted: {
    color: theme.palette.lm.primary.bMain
  },
  highlightedLight: {
    color: theme.palette.lm.primary.aMain
  }
}))

export default function PrimaryAppBar() {
  const classes = useStyles()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false)
  const openAccountDialog = () => setIsAccountDialogOpen(true)
  const closeAccountDialog = () => setIsAccountDialogOpen(false)

  const { numItems } = useCart()

  const history = useHistory()

  const routeButtonClasses = useCallback(
    (path) => `${classes.linkButton} ${history.location.pathname === path ? classes.highlightedLight : ''}`
    , [history.location.pathname, classes.linkButton, classes.highlightedLight]
  )

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Link component={RouterLink} to="/">
              <img src={logo} alt="logo povill" className={classes.logoImg} />
            </Link>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <ButtonGroup variant="text" size="large">
                <Button
                  component={RouterLink}
                  to="/about"
                  className={routeButtonClasses('/about')}
                >
                  Nosotros
                </Button>
                <Button
                  component={RouterLink}
                  to="/faqs"
                  className={routeButtonClasses('/faqs')}
                >
                  FAQS
                </Button>
                <Button
                  component={RouterLink}
                  to="/contact"
                  className={routeButtonClasses('/contact')}
                >
                  Contacto
                </Button>
                <Button
                  className={`${classes.linkButton} ${classes.highlighted}`}
                  startIcon={<LmAccount />}
                  onClick={openAccountDialog}
                >
                  Mi cuenta
                </Button>
                <Button
                  className={`${classes.linkButton} ${classes.highlighted}`}
                  startIcon={
                    <Badge badgeContent={numItems} color="secondary">
                      <LmCart />
                    </Badge>
                  }
                  component={RouterLink}
                  to="/cart"
                >
                  Mi carrito
                </Button>
              </ButtonGroup>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <LmMenu width="1.3em" height="1.3em" />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem>
            <Button
              className={classes.linkButton}
              component={RouterLink}
              to="/about"
            >
              Nosotros
            </Button>
          </MenuItem>
          <MenuItem>
            <Button
              className={classes.linkButton}
              component={RouterLink}
              to="/faqs"
            >
              FAQS
            </Button>
          </MenuItem>
          <MenuItem>
            <Button
              className={classes.linkButton}
              component={RouterLink}
              to="/contact"
            >
              Contacto
            </Button>
          </MenuItem>
          <MenuItem onClick={openAccountDialog}>
            <Button
              className={`${classes.linkButton} ${classes.highlighted}`}
              startIcon={<LmAccount />}
            >
              Mi cuenta
            </Button>
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/cart"
          >
            <Button
              className={`${classes.linkButton} ${classes.highlighted}`}
              startIcon={
                <Badge badgeContent={numItems} color="secondary">
                  <LmCart />
                </Badge>
              }
            >
              Mi carrito
            </Button>
          </MenuItem>
        </Menu>
      </div>

      <AccountDialog open={isAccountDialogOpen} onClose={closeAccountDialog} />
    </>
  )
}
