import React from 'react'
import { Paper, Grid, makeStyles } from '@material-ui/core'
import { useCart } from '../functions/CartContext';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  paper: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
    borderRadius: "0%"
  },
}));

function ConfirmationItem({ item }) {
  const classes = useStyles();
  const { useMemoItemPriceFormatted } = useCart()
  const itemPrice = useMemoItemPriceFormatted(item);

  return (
    <Paper key={item.id} variant="outlined" style={{ marginBottom: "16px" }}>
      <Grid container>
        <Grid item xs>
          <Paper className={classes.paper}><b>{item.product.name}</b></Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}><b>Preparación</b></Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}><b>Cantidad</b></Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Paper className={classes.paper}>
            Precio: {itemPrice}
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <span>
              {item.method.name}
            </span>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>{item.quantity} [{item.measure.name}].</Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default function ProductConfirmationList(props) {
  const items = props.items;

  return (
    <div>
      {
        items.map((item, index) =>
          <ConfirmationItem key={index} item={item} />
        )
      }
    </div>
  )

}