import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import Footer from '../components/Footer'
import PrimaryAppBar from '../components/PrimaryAppBar'
import mainImage from '../assets/images/povill_peix.jpg'
import MainContainer from '../components/MainContainer'
import useDocumentTitle from '../functions/useDocumentTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff'
  },
  title: {
    fontSize: '40px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      textAlign: 'center'
    }
  },
  text: {
    fontSize: '20px',
    color: theme.palette.lm.primary.bMain,
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
      textAlign: 'center'
    }
  },
  mainImageContainer: {
    [theme.breakpoints.down('sm')]: {
      order: '-1'
    }
  },
  mainImage: {
    objectFit: 'cover',
    borderRadius: '50%',
    width: 'min(45vw, 600px)',
    height: 'min(45vw, 600px)',
    borderWidth: 'min(4vw, 2rem)',
    borderStyle: 'solid',
    borderColor: '#E8E9BB',
    [theme.breakpoints.down('sm')]: {
      width: '75vw',
      height: '75vw',
      maxWidth: '600px',
      maxHeight: '600px'
    }
  }
}))

export default function About() {
  useDocumentTitle('Povill: Nosotros')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PrimaryAppBar />
      <MainContainer>
        <Grid
          container
          component="main"
          className={classes.mainContainer}
          spacing={2}
        >
          <Grid item container xs={12} md={5} direction="column" spacing={2}>
            <Grid item>
              <Typography
                variant="h4"
                className={classes.title}
              >
                ¿Quiénes somos?
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                className={classes.text}
              >
                Somos la Pescadería Povill, una tienda de familia monistrolenca de tercera generación dedicada al negocio del pescado.<br />
                Dando servicio desde 1940, elegimos y vendemos el mejor pescado fresco y marisco de la zona. Situados en el corazón de la ciudad de Manresa y al pie de Montserrat, en el pueblo del Bruc.<br />
                Mostrando nuestro espíritu innovador y de servicio, ahora también encontrarás nuestro producto por internet.<br />
                Estamos encantados de servirte un producto de altísima calidad que disfrutarás de verdad.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={7}
            justify="center"
            alignItems="flex-start"
            className={classes.mainImageContainer}
          >
            <img src={mainImage} alt="peixateria Povill" className={classes.mainImage} />
          </Grid>
        </Grid>
      </MainContainer>
      <Footer />
    </div>
  )
}
