import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()
  const anchorRef = useRef(null)

  useEffect(() => {
    if (anchorRef.current) {
      anchorRef.current.scrollIntoView(true)
    }
  }, [pathname, anchorRef])

  return (
    <div style={{ width: 0, height: 0 }} ref={anchorRef} />
  )
}
