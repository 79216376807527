import { Grid, Link, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import Footer from '../components/Footer'
import MainContainer from '../components/MainContainer'
import PrimaryAppBar from '../components/PrimaryAppBar'
import LmLogoIg from '../components/LM-Icons/LmLogoIg'
import useDocumentTitle from '../functions/useDocumentTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff'
  },
  title: {
    fontSize: '40px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      textAlign: 'center'
    }
  },
  subtitle: {
    fontWeight: 600,
    color: '#bcbcbc',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  regularText: {
    fontSize: '20px',
    color: theme.palette.lm.text.black,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  linkText: {
    fontSize: '23px',
    fontWeight: 700,
    color: theme.palette.lm.primary.bMain
  },
  circle: {
    width: 'min(45vw, 600px)',
    height: 'min(45vw, 600px)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E8E9BB',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
      width: '100vw',
      height: 'auto',
      padding: '1.5rem'
    }
  },
  circleContent: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  socialLogos: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}))

export default function Contact() {
  useDocumentTitle('Povill: Contacto')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PrimaryAppBar />
      <MainContainer>
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            md={5}
          >
            <Grid
              item
              container
              spacing={3}
              direction="column"
            >
              <Grid item>
                <Typography
                  variant="h4"
                  className={classes.title}
                >
                  Contáctanos
              </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  className={classes.subtitle}
                >
                  ¿Quieres contactar con nosotros?
              </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  className={classes.regularText}
                >
                  Nos encontrarás aquí:<br />
                  C/Barcelona, 2 - 08242, Manresa,<br />
                  Barcelona
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={7}
            justify="center"
            alignItems="flex-start"
          >
            <div className={classes.circle}>
              <Grid
                container
                direction="column"
                spacing={3}
                className={classes.circleContent}
              >
                <Grid item container direction="column" justify="center">
                  <Grid item>
                    <Typography variant="body1" className={classes.regularText}>
                      Escríbenos a:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}
                      variant="body1"
                      className={classes.linkText}
                    >
                      {process.env.REACT_APP_CONTACT_MAIL}
                    </Link>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.regularText}>
                      ¡Te escuchamos!
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      href={`tel:+34-${process.env.REACT_APP_CONTACT_PHONE}`}
                      variant="body1"
                      className={classes.linkText}
                    >
                      {process.env.REACT_APP_CONTACT_PHONE.replace(/-/g, ' ')}
                    </Link>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.regularText}>
                      ¡Síguenos!
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} spacing={1} className={classes.socialLogos}>
                    <Grid item>
                      <Link target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/peixateriespovill/">
                        <LmLogoIg width="3em" height="3em" />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </MainContainer>
      <Footer />
    </div>
  )
}
