import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Paper, Typography, Grid, Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import "./Login.css"
import { useAuth } from '../../functions/AuthContext'
import LogoMain from '../../components/LogoMain'
import googleSignIn from '../../assets/images/btn_google.png'

export default function Login() {
  const history = useHistory()
  const location = useLocation()
  const [formState, setFormState] = useState("main_form")
  const [errorState, setErrorState] = useState(null)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [showRecoveryMsg, setShowRecoveryMsg] = useState(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const auth = useAuth()

  function returnAfterLogin() {
    const { from } = location.state || { from: { pathname: "/" } }
    history.replace(from)
  }

  function startGoogleLogin() {
    auth.signInWithGooglePopup().then(returnAfterLogin)
  }

  function registerAccount() {
    auth.createUserWithEmailAndPassword(email, password)
      .then(function () {
        updateForm("main_form")
        setErrorState(`Tienes que validar tu email. Te hemos enviado un link de verificación a ${email}.`)
        setPassword("")
      }).catch(function (error) {
        setErrorState(getErrorCodeMessage(error.code))
      })
  }

  function loginUsingEmail() {
    auth.signInWithEmailAndPassword(email, password)
      .then(function (user) {
        if (!user.emailVerified) {
          user.sendEmailVerification()
          setErrorState(`Tienes que validar tu email. Te hemos enviado un link de verificación a ${email}.`)
          auth.signOut()
          setPassword("")
        } else {
          returnAfterLogin()
        }
      }).catch(function (error) {
        setErrorState(getErrorCodeMessage(error.code))
        if (error.code === "auth/wrong-password")
          setShowPasswordReset(true)
      })
  }

  function resetPassword() {
    setShowRecoveryMsg(true)
    setErrorState(null)
    auth.sendPasswordResetEmail(email).then(function () {
      setShowPasswordReset(false)
    }).catch(function (error) {
      setErrorState(`Ha ocurrido un error al enviar el email a ${email}.`)
      console.log(error)
    })
  }

  function getErrorCodeMessage(errorcode) {
    if (errorcode === "auth/invalid-email") {
      return "El email no es válido."
    } else if (errorcode === "auth/user-not-found") {
      return "Ese usuario no existe."
    } else if (errorcode === "auth/wrong-password") {
      return "La contraseña no es correcta."
    } else if (errorcode === "auth/weak-password") {
      return "La contraseña es muy corta."
    } else if (errorcode === "auth/email-already-in-use") {
      return "Ese email ya está en uso."
    } else if (errorcode === "auth/too-many-requests") {
      return "Has superado el límite de intentos, prueba más tarde."
    } else if (errorcode === "auth/argument-error") {
      return "Los datos introducidos no son válidos."
    }
    return null
  }

  function updateForm(form) {
    setErrorState(null)
    setShowPasswordReset(false)
    setShowRecoveryMsg(false)
    setFormState(form)
  }

  return (
    <Paper className="paper">
      <LogoMain />

      {
        // Pantalla principal
        formState === "main_form" ?

          <div className="login-form">

            <Typography component="h4" variant="h4" align="center" className="title" gutterBottom>
              Iniciar sesión
            </Typography>

            {
              (errorState != null) ?
                <div className="error">* {errorState}</div>
                :
                null
            }

            {
              showRecoveryMsg ?
                <div className="recoveryMsg">* Te hemos enviado un email para recuperar tu contraseña.</div>
                :
                null
            }

            <TextField
              style={{ width: '100%', margin: '0 0 20px 0' }}
              label="Email"
              variant="outlined"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />

            <TextField
              style={{ width: '100%', margin: '0 0 20px 0' }}
              type="password"
              label="Contraseña"
              variant="outlined"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

            <Grid container justify="space-between" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button style={{ minWidth: '100%' }} type="submit" variant="contained" color="primary" onClick={loginUsingEmail}>Entrar</Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button style={{ minWidth: '100%' }} variant="contained" onClick={() => updateForm("register_email")}>Crear una cuenta</Button>
              </Grid>
            </Grid>

            {(showPasswordReset) ? <div className="resetPasswordMsg" onClick={resetPassword}>¿Has olvidado tu contraseña? Haz click aquí.</div> : null}

          </div>

          :

          // Registro por email
          formState === "register_email" ?

            <div className="login-form">

              <Typography component="h4" variant="h4" align="center" className="title" gutterBottom>Crear una cuenta</Typography>

              {
                (errorState != null) ?
                  <div className="error">* {errorState}</div>
                  :
                  null

              }

              <TextField
                style={{ width: '100%', margin: '0 0 20px 0' }}
                label="Email"
                variant="outlined"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <TextField
                style={{ width: '100%', margin: '0 0 20px 0' }}
                type="password"
                label="Contraseña"
                variant="outlined"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    style={{ minWidth: '100%' }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={registerAccount}
                  >
                    Crear cuenta
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    style={{ minWidth: '100%' }}
                    variant="contained"
                    onClick={() => updateForm("main_form")}
                  >
                    Regresar al login
                  </Button>
                </Grid>
              </Grid>

            </div>

            :

            null
      }


      <Paper className="googlePaper">
        <Grid container justify="center">
          <img
            id="gmailButton"
            src={googleSignIn}
            alt="sign in with Google"
            width="186"
            height="41"
            onClick={startGoogleLogin}
          />
        </Grid>
      </Paper>

    </Paper>

  )

}