import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'
import { Route } from 'react-router-dom'
import { useStoreSchedule } from '../functions/StoreScheduleContext'
import ClosedStore from './ClosedStore'

export default function OnlyOpenStoreRoute({ component: Component, ...props }) {
  const storeSchedule = useStoreSchedule()

  return (
    <Route
      {...props}
      render={() => (
        storeSchedule === null ?
          (
            <Backdrop
              open={true}
            >
              <CircularProgress color="secondary" />
            </Backdrop>
          ) : (
            storeSchedule.isOpen ?
              <Component />
              :
              <ClosedStore storeSchedule={storeSchedule} />
          )

      )
      }
    />
  )
}
