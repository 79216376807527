import * as React from "react";

function SvgLmCart(props) {
  return (
    <svg
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.8 25.93"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M30.65 4.74a.66.66 0 00-.5-.23H6.34l-.81-4A.65.65 0 004.89 0H.65a.65.65 0 000 1.3h3.71l4 19.57a2.87 2.87 0 104.29.59h9A2.88 2.88 0 1026.9 23a2.85 2.85 0 00-.49-1.6.65.65 0 00-.16-1.28H9.5L9.26 19h16.49A3.25 3.25 0 0029 16.28l1.83-11a.65.65 0 00-.14-.52zM11.77 23a1.58 1.58 0 11-1.57-1.58A1.57 1.57 0 0111.77 23zM24 24.62A1.58 1.58 0 1125.6 23a1.58 1.58 0 01-1.6 1.62zm3.66-8.56a2 2 0 01-1.93 1.64H9L6.6 5.81h22.78z"
        fill="#7d8123"
      />
    </svg>
  );
}

export default SvgLmCart;
