import * as React from "react";

function SvgLmLocation(props) {
  return (
    <svg
      id="LM_Location_svg__Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.18 30.07"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>{".LM_Location_svg__cls-1{fill:#7d8123}"}</style>
      </defs>
      <path
        className="LM_Location_svg__cls-1"
        d="M10.59 0A10.6 10.6 0 000 10.59c0 5.67 9.77 18.42 10.19 19l.4.52.4-.52c.41-.54 10.19-13.29 10.19-19A10.61 10.61 0 0010.59 0zm0 27.15C8.9 24.89 1.89 15.25 1.89 11a8.7 8.7 0 0117.4 0c0 4.25-7.01 13.89-8.7 16.15z"
      />
      <path
        className="LM_Location_svg__cls-1"
        d="M10.59 7.06a3.53 3.53 0 103.53 3.53 3.53 3.53 0 00-3.53-3.53zm0 5.46a1.93 1.93 0 111.93-1.93 1.92 1.92 0 01-1.93 1.93z"
      />
    </svg>
  );
}

export default SvgLmLocation;
