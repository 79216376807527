import { Button, makeStyles } from '@material-ui/core'
import { AccountCircleOutlined } from '@material-ui/icons'
import React, { useState } from 'react'
import AccountDialog from './AccountDialog'

const useStyles = makeStyles((theme) => ({
  accountButton: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 2,
    top: 0,
    right: 0,
    color: '#fff',
    borderRadius: '0 0 0 1rem'
  }
}))

export default function AccountButton() {
  const classes = useStyles()

  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false)
  const openAccountDialog = () => setIsAccountDialogOpen(true)
  const closeAccountDialog = () => setIsAccountDialogOpen(false)

  return (
    <>
      <Button
        className={classes.accountButton}
        variant="contained"
        color="primary"
        startIcon={<AccountCircleOutlined />}
        onClick={openAccountDialog}
      >
        Mi cuenta
      </Button>

      <AccountDialog
        open={isAccountDialogOpen}
        onClose={closeAccountDialog}
      />
    </>
  )
}
