import React, { useEffect, useState } from 'react'
import { useCart } from '../functions/CartContext'
import { useHistory } from 'react-router-dom'
import 'firebase/database';
import { Grid, Button, makeStyles, Paper } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import TextField from '@material-ui/core/TextField';
import StepLabel from '@material-ui/core/StepLabel';
import ProductConfirmationList from '../components/ProductConfirmationList'
import { CheckCircleOutline } from '@material-ui/icons'
import useDocumentTitle from '../functions/useDocumentTitle';
import { useAuth } from "../functions/AuthContext";
import LogoMain from '../components/LogoMain';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  layout: {
    marginTop: 65,
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    }
  },
  textinputs: {
    width: "100%"
  }
}))


export default function Checkout() {
  useDocumentTitle("Povill: Finalizar compra")
  const history = useHistory();
  const classes = useStyles();
  const { user } = useAuth();
  const { cartState, cartDispatch, totalPrice, formatPrice } = useCart()
  const totalStr = formatPrice(totalPrice);
  const [activeStep, setActiveStep] = useState(0);
  const [shopContactDetails, setShopContactDetails] = useState({phone: "", clientEmail: ""});
  const [clientDetails, setClientDetails] = useState({name: user.displayName, phone: ""});
  const [clientDetailsError, setClientDetailsError] = useState("");
  const [finalPrice, setFinalPrice] = useState("");

  useEffect(() => {
    if(cartState.items.length === 0) {
      history.push("/cart")
      return
    }
    setFinalPrice(formatPrice(totalPrice))
    fetch("/api/config/public/povill").then(res => res.json()).then(data => {
      setShopContactDetails(data);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const finishPurchase = () => {
    history.push("/")
  }

  function validateClientDetails() {
    if(clientDetails.name.length === 0)
      setClientDetailsError("Tienes que introducir tu nombre.")
    else if(clientDetails.phone.length < 9 || isNaN(clientDetails.phone))
      setClientDetailsError("El teléfono indicado no es válido.")
    else {
      setClientDetailsError("")
      setActiveStep(2)
      savePurchase()
      cartDispatch({ type: 'emptyCart' })
    }
  }

  function savePurchase() {
    axios.post(`/api/nopayment/add/${user.uid}`, {
      name: clientDetails.name,
      phone: clientDetails.phone,
      email: user.email,
      items: cartState,
    })
  }

  function getFirstWindow() {
    return (
      <React.Fragment>
        <ProductConfirmationList items={cartState.items} />
        <p>Total: {totalStr}</p>
        <Grid container direction="row" justify="space-between">
          <Button variant="contained" onClick={() => history.goBack()}>
            Volver
          </Button>
          <Button variant="contained" color="secondary" onClick={() => setActiveStep(1)}>
            Siguiente
          </Button>
        </Grid>
      </React.Fragment>
    )
  }
  
  function getSecondWindow() {
    return (
      <React.Fragment>
        <Grid container direction="row" spacing={3} justify="center">
          <Grid item style={{color: "red"}}>
            {clientDetailsError}
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Nombre" 
              variant="outlined" 
              value={clientDetails.name} 
              className={classes.textinputs} 
              onChange={e => setClientDetails({...clientDetails, name: e.target.value})}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Teléfono" 
              variant="outlined" 
              className={classes.textinputs} 
              onChange={e => setClientDetails({...clientDetails, phone: e.target.value})}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Email" 
              variant="outlined" 
              value={user.email} 
              disabled={true} 
              className={classes.textinputs} 
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between" style={{marginTop: "16px"}}>
          <Button variant="contained" onClick={() => () => setActiveStep(0)}>
            Volver
          </Button>
          <Button variant="contained" color="secondary" onClick={() => validateClientDetails()}>
            Siguiente
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  function getThirdWindow() {
    return (
      <React.Fragment>
        <Paper variant="outlined" style={{paddingBottom: "30px"}}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item style={{paddingTop: "25px"}}>
              <CheckCircleOutline style={{ fontSize: 100, color: "#366f66" }} />
            </Grid>
            <Grid item xs={12} style={{fontSize: 18, padding: "20px"}}>
              <p>Estimado cliente,</p>
              <p>Su pedido esta en proceso. En cuanto tengamos preparado su pedido le 
              enviaremos un correo electrónico para que pueda pasar a recogerlo.</p>
              <p>El pago estimado es de {finalPrice} y se realizará a la entrega de los productos en la pescadería.</p>
              <p>Muchas gracias por confiar en nosotros y cualquier duda puede 
              contactarnos en el siguiente teléfono: {shopContactDetails.phone}.</p>
            </Grid>
          </Grid>
        </Paper>
        <Grid style={{paddingTop: "30px"}}></Grid>
        <Grid container direction="row" justify="flex-end">
          <Button variant="contained" color="secondary" style={{ marginTop: "20px" }} onClick={finishPurchase} >
            Finalizar
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <div>
      <LogoMain />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper alternativeLabel activeStep={activeStep}>
              <Step key='Confirmar pedido'>
                <StepLabel>Confirmar pedido</StepLabel>
              </Step>
              <Step key='Datos de contacto'>
                <StepLabel>Datos de contacto</StepLabel>
              </Step>
              <Step key='Pedido realizado'>
                <StepLabel>Pedido realizado</StepLabel>
              </Step>
          </Stepper>
          <Grid container direction="column" justify="center" alignItems="stretch" style={{marginTop: "16px"}}>
            <Grid item xs={12}>
            {
              activeStep === 0 ?
                getFirstWindow()
              : activeStep === 1 ?
                getSecondWindow()
              :
                getThirdWindow()
            }
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div >
  )

}