import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React from 'react'
import Footer from '../components/Footer'
import MainContainer from '../components/MainContainer'
import PrimaryAppBar from '../components/PrimaryAppBar'
import faqs from '../assets/faqs.json'
import useDocumentTitle from '../functions/useDocumentTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff'
  },
  title: {
    fontSize: '40px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      textAlign: 'center'
    }
  },
  subtitle: {
    fontSize: '20px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
      textAlign: 'center'
    }
  },
  question: {
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.lm.text.gray
  },
  answer: {
    whiteSpace: 'pre-line',
    fontSize: '16px',
    color: theme.palette.lm.text.gray
  }
}))

export default function FAQS() {
  useDocumentTitle('Povill: FAQS')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PrimaryAppBar />
      <MainContainer>
        <Grid
          container
          component="main"
          className={classes.mainContainer}
          spacing={2}
          direction="column"
        >
          <Grid item>
            <Typography
              variant="h4"
              className={classes.title}
            >
              FAQS
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              className={classes.subtitle}
            >
              Estas son algunas de las preguntas frecuentes, ¡esperamos resolver tus dudas!
            </Typography>
          </Grid>
          <br />
          <Grid item>
            {
              faqs.map(({ q, a }) =>
                <Accordion key={q} elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      variant="subtitle2"
                      className={classes.question}
                    >
                      {q}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className={classes.answer}
                    >
                      {a}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            }
          </Grid>
        </Grid>
      </MainContainer>
      <Footer />
    </div>
  )
}
