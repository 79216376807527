import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button, Grid, FormControlLabel, Typography, Checkbox, DialogTitle,
  DialogContent, DialogActions, Dialog, makeStyles
} from '@material-ui/core'
import { useAuth } from '../functions/AuthContext'

const useStyles = makeStyles((theme) => ({
  deleteAccount: {
    backgroundColor: "red",
    color: "white"
  },
  logout: {
    width: "100%"
  }
}))

export default function AccountDialog({ open, onClose }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [advancedSettings, setAdvancedSettings] = useState(false)
  const { user, deleteAccount, signOut } = useAuth()

  // If open check valid user
  useEffect(() => {
    if (open && !(user && user.emailVerified)) {
      history.push({ pathname: "/login", state: { from: location } })
    }
  }, [history, open, user, location])

  function checkPurchases() {
    history.push("/purchases")
  }

  function onClickDeleteAccount() {
    deleteAccount()
    onClose()
  }

  function onClickSignOut() {
    signOut()
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Detalles de cuenta</DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Usuario: {user?.email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Historial de compras:</Typography>
            <Button
              onClick={checkPurchases}
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
            >
              Ver mis compras
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={advancedSettings}
                  onChange={(event) => { setAdvancedSettings(event.target.checked) }}
                  name="checkedAdvanced"
                  color="primary"
                />
              }
              label={<Typography style={{ color: 'black' }}>Mostrar ajustes avanzados</Typography>}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: -24 }}>
            {
              !advancedSettings ? null :
                <Button
                  className={classes.deleteAccount}
                  onClick={onClickDeleteAccount}
                  variant="contained"
                  color="primary"
                >
                  Borrar cuenta
                </Button>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.logout}
          onClick={onClickSignOut}
          variant="contained"
          color="primary"
        >
          Cerrar sesión
        </Button>
      </DialogActions>
    </Dialog>
  )
}