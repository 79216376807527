import {
  CircularProgress, FormControlLabel, Grid, IconButton, InputAdornment, makeStyles, Paper,
  SwipeableDrawer, Switch, TextField
} from '@material-ui/core'
import { ChevronRight, Search } from '@material-ui/icons'
import React, { useMemo, useState } from 'react'
import ProductsListCard from './ProductsListCard'

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '450px',
    maxWidth: '90vw',
    height: '100%'
  },
  fixedTopContainer: {
    height: '110px',
    width: '100%',
    position: 'relative',
    zIndex: 3
  },
  topGrid: {
    height: '110px',
    width: '100%',
    paddingRight: '1rem'
  },
  scrollContainer: {
    padding: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100% - 110px)',
    overflowY: 'auto'
  }
}))

export default function ProductsListDrawer({
  products, isOpen, onlyImageChecked, toggleOnlyImageChecked, onClose, onClickProduct
}) {
  const classes = useStyles()

  const [searchText, setSearchText] = useState('')
  function onChangeSearch(event) {
    setSearchText(event.target.value)
  }

  const filteredProducts = useMemo(() => (
    products?.filter(product =>
      product.name.toLowerCase().includes(searchText.toLowerCase())
    ) || []
  ), [products, searchText])

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      disableSwipeToOpen={true}
      onOpen={() => { }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <div className={classes.mainContainer}>
        <div className={classes.fixedTopContainer}>
          <Paper square>
            <Grid
              container
              className={classes.topGrid}
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <IconButton onClick={onClose}>
                  <ChevronRight />
                </IconButton>
              </Grid>
              <Grid item container xs spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="search"
                    autoFocus
                    placeholder="Buscar"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                    value={searchText}
                    onChange={onChangeSearch}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={onlyImageChecked}
                        onChange={toggleOnlyImageChecked}
                      />
                    }
                    label="Sólo en esta imagen"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Grid>

          </Paper>
        </div>
        <div className={classes.scrollContainer}>
          {
            products === null ?
              <Grid container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
              :
              products.length === 0 ?
                <p>No hay productos disponibles en esta cámara</p>
                :
                <Grid
                  container
                  spacing={2}
                  alignContent="flex-start"
                >
                  {
                    filteredProducts.map(product =>
                      <Grid item xs={12} key={product._id}>
                        <ProductsListCard
                          product={product}
                          onClickProduct={onClickProduct}
                        />
                      </Grid>
                    )
                  }
                </Grid>
          }
        </div>
      </div>
    </SwipeableDrawer>
  )
}
