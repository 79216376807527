import { Divider, makeStyles, MobileStepper, Button, Paper } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight, SwitchCamera } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  cameraIndicatorContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: '1rem'
  },
  cameraIndicator: {
    padding: '0.5rem 1rem'
  },
  stepper: {
    borderRadius: '1rem',
  },
}))

export default function CameraStepper({ steps, activeStep, onClickBack, onClickNext }) {
  const classes = useStyles()

  return (
    <Paper className={classes.cameraIndicatorContainer}>
      <div className={classes.cameraIndicator}>
        <SwitchCamera />
      </div>
      <Divider orientation="vertical" flexItem />
      <MobileStepper
        className={classes.stepper}
        variant="dots"
        steps={steps}
        position="static"
        activeStep={activeStep}
        backButton={
          <Button size="small" onClick={onClickBack}>
            <KeyboardArrowLeft />
          </Button>
        }
        nextButton={
          <Button size="small" onClick={onClickNext}>
            <KeyboardArrowRight />
          </Button>
        }
      />
    </Paper>
  )
}
