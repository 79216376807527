import React from 'react'
import PrimaryAppBar from '../components/PrimaryAppBar'
import MainContainer from '../components/MainContainer'
import Footer from '../components/Footer'
import { makeStyles, Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import useDocumentTitle from '../functions/useDocumentTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff'
  },
  title: {
    fontSize: '40px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      textAlign: 'center'
    }
  }
}))

export default function PrivacyPolicy() {
  useDocumentTitle('Povill: Privacidad')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PrimaryAppBar />
      <MainContainer>
        <Typography
          variant="h1"
          className={classes.title}
        >
          Política de privacidad
        </Typography>
        <div>
          <p>PEIXATERIAPOVILL te informa sobre su <b>Política de privacidad</b> respecto al tratamiento y protección de los datos de carácter personal de los usuarios y clientes que puedan ser recabados por la navegación, contacto o contratación de servicios a través del sitio web  <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link></p>
          <p>En este sentido, PEIXATERIAPOVILL garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD).</p>
          <p>El uso de este sitio web implica la aceptación de esta Política de privacidad, así como las condiciones incluidas en el <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://safetykids.es/aviso-legal/">Aviso legal</Link>.</p>
          <h2>Identidad del responsable </h2>
          <p>Titular: MARTA POVILL I CLARET</p>
          <p>Denominación fiscal: MARTA POVILL I CLARET</p>
          <p>Nombre comercial (marca): PEIXATERIAPOVILL</p>
          <p>Domicilio social: c/Barcelona 2, 08242 Manresa, Barcelona</p>
          <p>Correo electrónico: <Link color="textSecondary" href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>{process.env.REACT_APP_CONTACT_MAIL}</Link></p>
          <p>Sitio web: <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link></p>
          <h2>Principios aplicados en el tratamiento de datos</h2>
          <p>En el tratamiento de los datos personales del usuario, PEIXATERIAPOVILL aplicará los siguientes principios, que se ajustan a las exigencias del Reglamento Europeo de Protección de Datos (RGPD) vigente:</p>
          <ul>
            <li><b>Principio de licitud, lealtad y transparencia</b>: PEIXATERIAPOVILL siempre requerirá el consentimiento para el tratamiento de tus datos personales que puede ser para uno o varios fines específicos sobre los que te informará previamente con absoluta transparencia.</li>
            <li><b>Principio de minimización de datos</b>: PEIXATERIAPOVILL te solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita.</li>
            <li><b>Principio de limitación del plazo de conservación</b>: los datos se mantendrán durante el tiempo estrictamente necesario para el fin o los fines del tratamiento. PEIXATERIAPOVILL te informará del plazo de conservación correspondiente según la finalidad. En el caso de suscripciones, PEIXATERIAPOVILL revisará periódicamente las listas y eliminará aquellos registros inactivos durante un tiempo considerable.</li>
            <li><b>Principio de integridad y confidencialidad</b>: tus datos serán tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada. Debes saber que PEIXATERIAPOVILL toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.</li>
          </ul>
          <h2>Obtención de datos personales</h2>
          <p>Para navegar por  <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link> no es necesario que facilites ningún dato personal. Los casos en los que sí proporcionas algunos de tus datos personales son los siguientes: </p>
          <ul>
            <li>Al contactar a través de los formularios de contacto o enviar un correo electrónico, para empezar a realizar una compra online. </li>
            <li>Al llamar por teléfono al Servicio de Atención al Cliente de PEIXATERIAPOVILL o enviar un mensaje de WhatsApp. </li>
          </ul>
          <h2>Tus derechos</h2>
          <p>PEIXATERIAPOVILL te informa que sobre tus datos personales tienes derecho a:</p>
          <ul>
            <li>Solicitar el acceso a los datos guardados. </li>
            <li>Solicitar una rectificación o la cancelación de los datos guardados. </li>
            <li>Oponerte a su tratamiento. </li>
            <li>Solicitar la portabilidad de tus datos. </li>
          </ul>
          <p>El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente a PEIXATERIAPOVILL, lo que significa que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento puede dirigirse a PEIXATERIAPOVILL y pedir información sobre los datos que la empresa tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, solicitar la portabilidad de sus datos personales, oponerse al tratamiento o solicitar la cancelación de esos datos en los ficheros de PEIXATERIAPOVILL. </p>
          <p>Para ejercer tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a <Link color="textSecondary" href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>{process.env.REACT_APP_CONTACT_MAIL}</Link> , junto con la prueba válida en Derecho, como una fotocopia del D.N.I. o equivalente.</p>
          <p>Tienes derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.</p>
          <h2>Finalidad del tratamiento de los datos personales</h2>
          <p>Cuando te conectas al sitio web para mandar un correo a PEIXATERIAPOVILL, te suscribes a su boletín (si existiera) o realizas una contratación o compra (si procede), estás facilitando información de carácter personal de la que el responsable es PEIXATERIAPOVILL. Esta información puede incluir datos de carácter personal como pueden ser tu dirección IP, nombre y apellidos, dirección física, dirección de correo electrónico, número de teléfono u otra información. Al facilitar esta información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por  <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link> , tal y como se describe en el Aviso Legal y en la presente Política de Privacidad.</p>
          <p>Estas son las principales finalidades por las que PEIXATERIAPOVILL trata tus datos personales:</p>
          <ol>
            <li>Responder a comunicaciones recibidas, gestionar una consulta, sugerencia, petición o gestionar una compra.</li>
            <li>Enviar información comercial y/o publicitaria sobre productos, servicios, novedades, promociones, actualidad, eventos, actividades, etc. Esta información será siempre relativa a productos o servicios de PEIXATERIAPOVILL, o de aquellos colaboradores o asociados con quienes la empresa haya establecido algún acuerdo de promoción comercial dirigida a sus clientes. En este caso, los terceros nunca tendrán acceso directo a datos personales, y las comunicaciones comerciales serán siempre realizadas por PEIXATERIAPOVILL sobre productos y servicios propios o relacionados con su sector de actividad.</li>
            <li>Analizar el número y tipología de peticiones recibidas, a fin de obtener estadísticas pertinentes para el desarrollo de la actividad de PEIXATERIAPOVILL.</li>
          </ol>

          <p>Existen otras finalidades por las que PEIXATERIAPOVILL trata tus datos personales:</p>
          <ul>
            <li>Para garantizar el cumplimiento de las condiciones recogidas en el Aviso legal y en la ley aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayuden a este sitio web a garantizar la confidencialidad de los datos personales que recoge. </li>
            <li>Para apoyar y mejorar los servicios que ofrece este sitio web. </li>
            <li>Para analizar la navegación. PEIXATERIAPOVILL recoge otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en tu dispositivo cuando navegas por el sitio web cuyas características y finalidad están detalladas en la Política de cookies. </li>
            <li>Para gestionar las redes sociales cuando proceda. PEIXATERIAPOVILL podría tener presencia en redes sociales. Si te haces seguidor en las redes sociales de PEIXATERIAPOVILL, el tratamiento de los datos personales se regirá por este apartado, así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y que has aceptado previamente.</li>
            <li>Puedes consultar las políticas de privacidad de las principales redes sociales en los enlaces o la sección destinados a informar de ello en cada una de las redes. </li>
          </ul>
          <p>PEIXATERIAPOVILL tratará tus datos personales con la finalidad de administrar correctamente su presencia en cada red social, informarte de sus actividades, productos o servicios, así como para cualquier otra finalidad que las normativas de las redes sociales permitan.</p>
          <p>En ningún caso PEIXATERIAPOVILL utilizará los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.</p>
          <h2>Seguridad de los datos personales</h2>
          <p>Para proteger tus datos personales, PEIXATERIAPOVILL toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso indebido, divulgación, alteración o destrucción de los mismos.</p>
          <p>El sitio web está alojado en <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://www.dondominio.com/">https://www.dondominio.com/</Link> de la empresa Soluciones Corporativas IP, SL, cuya política de privacidad es <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://www.dondominio.com/legal/55/politica-de-privacidad/">https://www.dondominio.com/legal/55/politica-de-privacidad/</Link>. La seguridad de tus datos está garantizada, ya que toman todas las medidas de seguridad necesarias para ello. Puedes consultar su política de privacidad para tener más información.</p>
          <h2>Contenidos de otros sitios web</h2>
          <p>Las páginas de este sitio web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras webs se comporta exactamente de la misma manera que si hubieras visitado la otra web.</p>
          <p>Estos sitios web pueden recopilar datos sobre ti, utilizar cookies, incrustar un código de seguimiento adicional de terceros, y supervisar tu interacción usando este código.</p>
          <h2>Política de cookies</h2>
          <p>Para que este sitio web funcione correctamente necesita utilizar cookies, que es una información que se almacena en tu navegador web.</p>
          <p>En la página Política de cookies puedes consultar toda la información relativa a la política de recogida, la finalidad y el tratamiento de las cookies.</p>
          <h2>Legitimación para el tratamiento de los datos</h2>
          <p>La base legal para el tratamiento de tus datos es: el consentimiento.</p>
          <p>Para contactar con PEIXATERIAPOVILL, suscribirte a un boletín en caso de que lo hubiera o realizar comentarios en este sitio web si fuera posible, tendrías que aceptar la presente Política de privacidad.</p>
          <h2>Categorías de datos personales</h2>
          <p>Las categorías de datos personales que trata PEIXATERIAPOVILL son: datos identificativos.</p>
          <h2>Conservación de datos personales</h2>
          <p>Los datos personales que proporciones a PEIXATERIAPOVILL se conservarán hasta que solicites su supresión.</p>
          <h2>Destinatarios de datos personales</h2>
          <p>Google Analytics es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Encontrarás más información en: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://analytics.google.com">https://analytics.google.com</Link></p>
          <p>Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu dispositivo, para ayudar a PEIXATERIAPOVILL a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca del uso del sitio web (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.</p>
          <p>También puedes ver una lista de los tipos de cookies que utiliza Google y sus colaboradores y toda la información relativa al uso que hacen de cookies publicitarias.</p>
          <h2>Navegación web</h2>
          <p>Al navegar por  <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link> se pueden recoger datos no identificativos, que pueden incluir: la dirección IP, geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte.</p>
          <p>El sitio web utiliza los siguientes servicios de análisis de terceros: <b>Google Analytics</b>.</p>
          <p>PEIXATERIAPOVILL utiliza la información obtenida para extraer datos estadísticos, analizar tendencias, administrar el sitio, estudiar patrones de navegación y recopilar información demográfica.</p>
          <h2>Exactitud y veracidad de los datos personales</h2>
          <p>Te comprometes a que los datos facilitados a PEIXATERIAPOVILL sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.</p>
          <p>Como usuario del sitio web eres el/la único/a responsable de la veracidad y corrección de los datos que remitas al sitio, exonerando a PEIXATERIAPOVILL de cualquier responsabilidad al respecto.</p>
          <h2>Aceptación y consentimiento</h2>
          <p>Como usuario del sitio web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos por parte de PEIXATERIAPOVILL en la forma y para las finalidades indicadas en esta Política de privacidad.</p>
          <h2>Revocabilidad</h2>
          <p>Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a <Link color="textSecondary" href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>{process.env.REACT_APP_CONTACT_MAIL}</Link> junto con la prueba válida en Derecho, como una fotocopia del D.N.I. o identificación equivalente.</p>
          <p>El ejercicio de tus derechos no incluye ningún dato que PEIXATERIAPOVILL esté obligado a conservar con fines administrativos, legales o de seguridad.</p>
          <h2>Cambios en la política de privacidad</h2>
          <p>PEIXATERIAPOVILL se reserva el derecho a modificar la presente Política de privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria.</p>
          <p>Estas políticas estarán vigentes hasta que sean modificadas por otra y queden debidamente publicadas.</p>
          <p><i>Última actualización de esta Política de privacidad: <b>diciembre 2020.</b></i></p>
        </div>
      </MainContainer>
      <Footer />
    </div>
  )
}
