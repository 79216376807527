import * as React from "react";

function SvgLmArrowRight(props) {
  return (
    <svg
      id="LM_ArrowRight_svg__Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67.36 67.36"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {
            ".LM_ArrowRight_svg__cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}"
          }
        </style>
      </defs>
      {/* <circle cx={33.68} cy={33.68} r={33.68} fill="#959547" /> */}
      <path
        className="LM_ArrowRight_svg__cls-2"
        d="M36.46 19.59l14.39 14.17-14.39 14.17M50.64 33.76H15.62"
      />
    </svg>
  );
}

export default SvgLmArrowRight;
