import React, { useEffect, useRef, useState } from 'react'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core'

// Use custom validation messages instead of element.validationMessage 
// because some browsers (Safari) do not give useful info
function getValidityErrorMessage(validity, data) {
  if (validity.valid) { return '' }

  let errorMessage = ''
  if (validity.badInput) {
    errorMessage = 'Debes introducir un número.'
  } else if (validity.rangeUnderflow) {
    errorMessage = `El valor debe ser superior o igual a ${data.min}.`
  } else if (validity.stepMismatch) {
    const numSteps = Math.floor(data.value / data.step)
    const previousValid = Math.max(numSteps * data.step, data.min)
    const nextValid = (numSteps + 1) * data.step
    errorMessage = `Introduce un valor válido. Los dos valores válidos más próximos son ${previousValid.toFixed(2)} y ${nextValid.toFixed(2)}.`
  } else if (validity.valueMissing) {
    errorMessage = 'Completa este campo.'
  } else {
    errorMessage = 'Error en el valor.'
  }

  return errorMessage
}

export default function ProductOrderForm({
  item, onChangeQuantity, onChangeMethod, onChangeMeasure, onChangeUserNote
}) {
  // On component load check quantity validity
  const inputEl = useRef(null)
  useEffect(() => {
    if (inputEl) {
      const errorMessage = getValidityErrorMessage(
        inputEl.current.validity,
        {
          value: inputEl.current.value,
          min: item.product.price.selectorQuantity.min,
          step: item.product.price.selectorQuantity.step
        }
      )
      setQuantityErrorMessage(errorMessage)
    }
  }, [item.product.price.selectorQuantity])

  const [quantityErrorMessage, setQuantityErrorMessage] = useState('')
  function handleQuantityChange(event) {
    const errorMessage = getValidityErrorMessage(
      event.target.validity,
      {
        value: event.target.value,
        min: item.product.price.selectorQuantity.min,
        step: item.product.price.selectorQuantity.step
      }
    )
    setQuantityErrorMessage(errorMessage)
    onChangeQuantity(event.target.value)
  }

  function handleMethodChange(event) {
    const selectedMethod = item.product.methods.find(measure => event.target.value === measure.name)
    onChangeMethod(selectedMethod)
  }

  function handleMeasureChange(event) {
    const selectedMeasure = item.product.price.selectorMeasure.find(measure => event.target.value === measure.name)
    onChangeMeasure(selectedMeasure)
  }


  function handleUserNoteChange(event) {
    onChangeUserNote(event.target.value)
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            inputRef={inputEl}
            name="quantity"
            type="number"
            inputProps={{
              min: item.product.price.selectorQuantity.min,
              step: item.product.price.selectorQuantity.step
            }}
            fullWidth
            value={item.quantity}
            onChange={handleQuantityChange}
            required
            error={quantityErrorMessage !== ''}
            helperText={quantityErrorMessage}
          />
        </Grid>
        <Grid item xs={6}>
          {
            item.product.price.selectorMeasure.length === 1 ?
              <TextField
                defaultValue={item.product.price.selectorMeasure[0].name}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
              />
              :
              <FormControl fullWidth required>
                <Select
                  labelId="measure-label"
                  id="measure"
                  name="measure"
                  value={item.measure.name}
                  onChange={handleMeasureChange}
                  required
                >
                  {
                    item.product.price.selectorMeasure.map(measure =>
                      <MenuItem
                        value={measure.name}
                        key={measure.name}
                      >
                        {measure.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
          }
        </Grid>
        <Grid item xs={12}>
          {
            item.product.methods.length === 1 ?
              <TextField
                label="Preparación"
                defaultValue={item.product.methods[0].name}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
              />
              :
              <FormControl fullWidth required>
                <InputLabel id="method-label">
                  Preparación
                </InputLabel>
                <Select
                  labelId="method-label"
                  id="method"
                  name="method"
                  value={item.method.name}
                  onChange={handleMethodChange}
                  required
                >
                  {
                    item.product.methods.map(method =>
                      <MenuItem
                        key={method.name}
                        value={method.name}
                      >
                        {method.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
          }
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ maxLength: 100 }}
            id="user-note"
            label="Nota para la tienda"
            multiline
            rows={3}
            rowsMax={3}
            value={item.userNote}
            onChange={handleUserNoteChange}
            variant="outlined"
            fullWidth
            helperText="Máximo 100 caracteres"
          />
        </Grid>
      </Grid>
    </div>
  )
}
