import React from 'react'
import {
  Typography, Paper, Grid, makeStyles, Button
} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { useCart } from '../functions/CartContext'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: 600
  },
  img: {
    width: '100%',
    height: 'auto'
  },
  subtotalPrice: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  }
}))

export default function CartItemCard({ item, onClickEdit, onClickDelete }) {
  const classes = useStyles()

  const { useMemoItemPriceFormatted } = useCart()
  const formattedPrice = useMemoItemPriceFormatted(item)

  return (
    <Paper className={classes.paper} variant="outlined">
      <Grid container spacing={2} direction="column" alignItems="stretch">
        <Grid item container spacing={3}>
          <Grid item xs={3}>
            <img
              className={classes.img}
              src={item.product.imageUrl}
              alt={item.product.name}
            />
          </Grid>
          <Grid item container xs={9} spacing={1} direction="column">
            <Grid item>
              <Typography variant="h6">
                {item.product.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {item.quantity} {item.measure.name} - {item.method.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color="textSecondary">
                {item.userNote}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                align="right"
                color="textSecondary"
                className={classes.subtotalPrice}
              >
                {formattedPrice}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={onClickDelete}
              variant="contained"
              color="default"
              startIcon={<Delete />}
            >
              Eliminar
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onClickEdit}
              variant="contained"
              color="secondary"
              startIcon={<Edit />}
            >
              Editar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
