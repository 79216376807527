import { Button, Card, CardContent, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import { AddShoppingCart, Check } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { useCart } from '../functions/CartContext'
import ProductOrderForm from './ProductOrderForm'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    maxWidth: '100%'
  },
  cardContent: {
    padding: '0 0 16px 0'
  },
  productImage: {
    width: '100px',
    height: 'auto'
  },
  form: {
    width: '100%'
  },
  productName: {
    fontWeight: 'bold'
  },
  price: {
    fontWeight: 'bold'
  },
  subtotalPrice: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  }
}))

export default function ItemCard({
  item, actionType, onSubmitAction, onClickCancel, onChangeQuantity, onChangeMethod,
  onChangeMeasure, onChangeUserNote
}) {
  const classes = useStyles()

  const { useMemoItemPriceFormatted, formatProductPrice } = useCart()
  const itemPriceFormatted = useMemoItemPriceFormatted(item)

  function onSubmit(event) {
    event.preventDefault()
    onSubmitAction()
  }

  const cardActions = useMemo(() => {
    switch (actionType) {
      case 'add':
        return (
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              startIcon={<AddShoppingCart />}
            >
              Añadir al carrito
          </Button>
          </Grid>
        )
      case 'cancel-save':
        return (
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={6}>
              <Button
                onClick={onClickCancel}
                fullWidth
                variant="contained"
                color="default"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                startIcon={<Check />}
              >
                Hecho
              </Button>
            </Grid>
          </Grid>

        )
      default:
        throw Error('actionType not valid')
    }
  }, [actionType, onClickCancel])

  // Format product price with the currency formatter but replacing the currency (15.1 > 15,10 € > 15,10 €/kg)
  const productPriceFormatted = useMemo(() =>
    item ? formatProductPrice(item.product) : 0
    , [formatProductPrice, item])

  return (
    <div className={classes.root}>
      {
        !item ?
          <Grid container justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
          :
          <Card elevation={0}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                <Grid item container spacing={1} alignItems="flex-end">
                  <Grid item container xs>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        className={classes.productName}
                      >
                        {item.product.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.price}
                      >
                        {productPriceFormatted}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <img
                      src={item.product.imageUrl}
                      alt={item.product.name}
                      width="400"
                      height="600"
                      className={classes.productImage}
                    />
                  </Grid>
                </Grid>

                {
                  item.product.description &&
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {item.product.description}
                    </Typography>
                  </Grid>
                }

                <Grid item xs={12}>
                  <form onSubmit={onSubmit} className={classes.form}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <ProductOrderForm
                          item={item}
                          onChangeQuantity={onChangeQuantity}
                          onChangeMethod={onChangeMethod}
                          onChangeMeasure={onChangeMeasure}
                          onChangeUserNote={onChangeUserNote}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          align="right"
                          color="textSecondary"
                          className={classes.subtotalPrice}
                        >
                          {itemPriceFormatted}
                        </Typography>
                      </Grid>
                      {cardActions}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
      }
    </div>
  )
}
