import { Card, CardActionArea, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useCart } from '../functions/CartContext'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  card: {
    display: 'flex'
  },
  cardMedia: {
    width: 60
  },
  price: {
    fontWeight: 'bold'
  }
}))

export default function ProductsListCard({ product, onClickProduct }) {
  const classes = useStyles()

  const { formatProductPrice } = useCart()

  const productPriceFormatted = useMemo(() =>
    product ? formatProductPrice(product) : 0
    , [formatProductPrice, product])

  return (
    <Grid item className={classes.root}>
      <CardActionArea
        onClick={() => onClickProduct(product._id)}
      >
        <Card
          className={classes.card}
          variant="outlined"
        >
          <CardMedia
            className={classes.cardMedia}
            image={product.imageUrl}
            title={product.name}
          />
          <CardContent>
            <Typography variant="h6" component="h4" gutterBottom>
              {product.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" className={classes.price}>
              {productPriceFormatted}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  )
}
