import * as React from "react";

function SvgLmMenu(props) {
  return (
    <svg
      id="LM_Menu_svg__Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.48 23.77"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {
            ".LM_Menu_svg__cls-1{fill:none;stroke:#7d8123;stroke-miterlimit:10;stroke-width:2px}"
          }
        </style>
      </defs>
      <path
        className="LM_Menu_svg__cls-1"
        d="M0 1h33.48M0 11.89h33.48M0 22.77h33.48"
      />
    </svg>
  );
}

export default SvgLmMenu;
