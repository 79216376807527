import React, { useState, useEffect, useContext, createContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

firebase.initializeApp({
  apiKey: 'AIzaSyBNQ_gMiJ3NR7-vaNbRKkRRSnJQqhscjIE',
  authDomain: 'virtualmarket-ugiat.firebaseapp.com',
  databaseURL: 'https://virtualmarket-ugiat.firebaseio.com',
  projectId: 'virtualmarket-ugiat',
  storageBucket: 'virtualmarket-ugiat.appspot.com',
  messagingSenderId: '833300804206',
  appId: '1:833300804206:web:48b2f203eb1868fab96c7f',
  measurementId: 'G-XXWR8TVGMJ'
})

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

const AuthContext = createContext()

function useAuth() {
  return useContext(AuthContext)
}

function AuthProvider({ children }) {
  const [user, setUser] = useState(null)

  async function signInWithEmailAndPassword(email, password) {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password)
    try {
      if (response.user) {
        window.gtag('event', 'login', { 'method': 'Email' })
      }
    } catch (error) {
      console.log(error)
    }
    return response.user
  }

  async function signInWithGooglePopup() {
    const response = await firebase.auth().signInWithPopup(googleAuthProvider)
    try {
      if (response.user) {
        window.gtag('event', 'login', { 'method': 'Google' })
      }
    } catch (error) {
      console.log(error)
    }
    return response.user
  }

  async function createUserWithEmailAndPassword(email, password) {
    const response = await firebase.auth().createUserWithEmailAndPassword(email, password)
    await response.user.sendEmailVerification()
    await fetch('/api/mail/newaccount/' + email)
    try {
      if (response.user) {
        window.gtag('event', 'sign_up', { 'method': 'Email' })
      }
    } catch (error) {
      console.log(error)
    }
    return response.user
  }

  async function signOut() {
    await firebase.auth().signOut()
  }

  async function sendPasswordResetEmail(email) {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  async function confirmPasswordReset(code, password) {
    return firebase.auth().confirmPasswordReset(code, password)
  }

  async function deleteAccount() {
    const email = user.email
    await user.delete()
    await fetch('/api/mail/deleteaccount/' + email)
  }

  // Subscribe to user on mount
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })

    // Cleanup subscription on unmount
    return unsubscribe
  }, [])

  const auth = {
    user,
    signInWithEmailAndPassword,
    signInWithGooglePopup,
    createUserWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    deleteAccount
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export { AuthProvider, useAuth }