import React from 'react'
import { useHistory } from 'react-router-dom'
import useDocumentTitle from '../functions/useDocumentTitle'

export default function RouteNotFound() {
  useDocumentTitle("Povill: Ruta no encontrada")

  const history = useHistory()

  return (
    <div style={{ width: '100vw', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      La ruta "{history.location.pathname}" no existe.
    </div>
  )
}
