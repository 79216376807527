import React from 'react'
import PrimaryAppBar from '../components/PrimaryAppBar'
import MainContainer from '../components/MainContainer'
import Footer from '../components/Footer'
import { makeStyles, Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import useDocumentTitle from '../functions/useDocumentTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff'
  },
  title: {
    fontSize: '40px',
    color: theme.palette.lm.primary.bMain,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      textAlign: 'center'
    }
  }
}))

export default function Cookies() {
  useDocumentTitle('Povill: Cookies')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PrimaryAppBar />
      <MainContainer>
        <Typography
          variant="h1"
          className={classes.title}
        >
          Política de cookies
        </Typography>
        <div>
          <p>MARTA POVILL I CLARET informa acerca del uso de las cookies en su comercio online de la página web: <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link> </p>
          <h2>¿Qué son las cookies?</h2>
          <p>Las cookies son archivos que se pueden descargar en tu equipo a través de las páginas web. Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros, permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.</p>
          <h2>Tipos de cookies</h2>
          <p>Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y según trate los datos que se obtengan se pueden distinguir dos tipos:</p>
          <ul>
            <li><b>Cookies propias</b>: aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</li>
            <li><b>Cookies de terceros</b>: aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li>
          </ul>
          <p>En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas cookies propias.</p>
          <p>Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de:</p>
          <ul>
            <li><b>Cookies de sesión</b>: diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (por ej. una lista de productos adquiridos).</li>
            <li><b>Cookies persistentes</b>: los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</li>
          </ul>
          <p>Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los datos obtenidos:</p>
          <ul>
            <li><b>Cookies técnicas</b>: aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales.</li>
            <li><b>Cookies de personalización</b>: permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario, como por ejemplo el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</li>
            <li><b>Cookies de análisis</b>: permiten al responsable de las mismas realizar el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma, y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</li>
            <li><b>Cookies publicitarias</b>: permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios.</li>
            <li><b>Cookies de publicidad comportamental</b>: almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
            <li><b>Cookies de redes sociales externas</b>: se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales (Facebook, YouTube, Twitter, LinkedIn, etc.) y que se generen únicamente paralos usuarios de dichas redes sociales. Las condiciones de utilización de estas cookies y la información recopilada se regulan por la política de privacidad de la plataforma social correspondiente.</li>
          </ul>
          <h2>Desactivación y eliminación de cookies</h2>
          <p>Tienes la opción de permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones de tu navegador. Al desactivar cookies, algunos de los servicios disponibles podrían dejar de estar operativos. La forma de deshabilitar las cookies es diferente para cada navegador, pero normalmente puede hacerse desde el menú <b>Herramientas</b> u <b>Opciones</b>. También puede consultarse el menú de <b>Ayuda</b> del navegador, donde puedes encontrar instrucciones. El usuario podrá en cualquier momento elegir qué cookies quiere que funcionen en este sitio web.</p>
          <p>El usuario puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:</p>
          <ul>
            <li>Microsoft Internet Explorer o Microsoft Edge: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies">enlace</Link>.</li>
            <li>Mozilla Firefox: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros">enlace</Link>.</li>
            <li>Chrome: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://support.google.com/accounts/answer/61416?hl=es">enlace</Link>.</li>
            <li>Safari: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/">enlace</Link>.</li>
            <li>Opera: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://help.opera.com/Linux/10.60/es-ES/cookies.html">enlace</Link>.</li>
          </ul>
          <p>Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las siguientes:</p>
          <ul>
            <li>Ghostery: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://www.ghostery.com/">enlace</Link>.</li>
            <li>Your online choices: <Link color="textSecondary" target="_blank" rel="noreferrer noopener" href="https://www.youronlinechoices.com/es/">enlace</Link>.</li>
          </ul>
          <h3>Cookies utilizadas en <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link></h3>
          <p>Siguiendo las directrices de la Agencia Española de Protección de Datos, a continuación se identifican las cookies que están siendo utilizadas en este sitio web, así como su tipología y función:</p>
          <p>Este sitio web utiliza las siguientes cookies propias:</p>
          <ul>
            <li>Cookies de sesión</li>
          </ul>
          <p>Este sitio web utiliza las siguientes cookies de terceros:</p>
          <ul>
            <li>Google Analytics: almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web, como usuario estás consintiendo el tratamiento de información acerca de ti y de tu sesión por parte de Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberás hacerlo comunicando directamente con Google.</li>
            <li>Redes sociales: cada red social utiliza sus propias cookies para que el usuario pueda pinchar en botones del tipo Me gusta o Compartir.</li>
          </ul>
          <h2>Aceptación de la Política de cookies</h2>
          <p><Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link> asume que el usuario acepta el uso de cookies. No obstante, muestra información sobre su Política de cookies en la parte inferior de cualquier página del sitio web con cada inicio de sesión, con el propósito de que siempre seas consciente.</p>
          <p>Ante esta información es posible llevar a cabo las siguientes acciones:</p>
          <ul>
            <li><b>Aceptar cookies</b>. No se volverá a visualizar este aviso al acceder a cualquier página del portal durante la presente sesión.</li>
            <li><b>Cerrar</b>. Se oculta el aviso en la presente página.</li>
            <li><b>Modificar su configuración</b>. Podrás obtener más información sobre qué son las cookies, conocer la Política de cookies de <Link color="textSecondary" component={RouterLink} to="/">{process.env.REACT_APP_BASE_URL}</Link> y modificar la configuración de su navegador.</li>
          </ul>
          <p><i>Última actualización de esta Política de cookies: <b>diciembre 2020.</b></i></p>
        </div>
      </MainContainer>
      <Footer />
    </div>
  )
}
