import { makeStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles(() => ({
  pullNavContainer: {
    position: 'fixed',
    top: 'calc(50% - 25px)',
    zIndex: 9999999,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  pullNav: {
    fontSize: 50
  }
}))

// pullLeft and pullRight have to be percentages from 0 to 1
export default function PullNav({ pullLeft, pullRight }) {
  const classes = useStyles()

  return (
    <>
      <div className={classes.pullNavContainer} style={{ transform: `translateX(${100 * pullLeft}px)`, left: -50 }}>
        <NavigateBefore className={classes.pullNav} style={{ opacity: pullLeft, transform: `rotate(${90 - 90 * pullLeft}deg)` }} />
      </div>
      <div className={classes.pullNavContainer} style={{ transform: `translateX(${-100 * pullRight}px)`, right: -50 }}>
        <NavigateNext className={classes.pullNav} style={{ opacity: pullRight, transform: `rotate(${-90 + 90 * pullRight}deg)` }} />
      </div>
    </>
  )
}
