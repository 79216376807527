import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../functions/AuthContext'

export default function PrivateRoute({ component: Component, ...props }) {
  const { user } = useAuth()

  return (
    <Route
      {...props}
      render={({ location }) =>
        user && user.emailVerified ?
          <Component />
          :
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
      }
    />
  )
}
