import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Button, makeStyles, Paper } from '@material-ui/core'
import { Typography } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons'
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useDocumentTitle from '../functions/useDocumentTitle';
import { useAuth } from "../functions/AuthContext";
import LogoMain from '../components/LogoMain';
import { useCart } from '../functions/CartContext';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  layout: {
    marginTop: 65,
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  up: {
    marginTop: "-65px"
  }
}))


export default function Purchases() {
  useDocumentTitle("Povill: Historial de compras");

  // REACT
  const history = useHistory()
  const classes = useStyles();
  const [purchases, setPurchases] = useState([]);
  const [picture, setPicture] = React.useState(null);
  const [expanded, setExpanded] = useState(-1);
  const { user } = useAuth()
  const { formatPrice } = useCart()

  useEffect(() => {
    async function fetchData() {
      var data = await axios.get(`/api/nopayment/get/${user.uid}`)
      setPurchases(data.data)
    }
    fetchData()
  }, [user]);

  const handleExpandClick = (index) => {
    setExpanded(expanded === index ? -1 : index)
    if(index !== -1)
      loadPictureFromServer(index);
  }

  function stringDate(date) {
    var split = date.split(":")
    var day = split[2] + "/" + split[1] + "/" + split[0]
    var time = split[3] + ":" + split[4] + ":" + split[5]
    return (<b>{day + " " + time}</b>)
  }

  function onClickBack() {
    history.goBack()
  }

  function loadPictureFromServer(index) {
    const uid = user.uid;
    fetch(`/api/nopayment/bill/${uid}/${purchases[index].code}`).then(response => response.json()).then(function (data) {
      if (data.bill !== "none")
        setPicture(data.bill)
    })
  }

  return (
    <div>
      <LogoMain />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={3} justify="center" alignItems="stretch">
            <Typography component="h4" variant="h4" align="center" className="title" gutterBottom>
              Mi historial de compras
            </Typography>
            {
              purchases.map((item, index) =>
                <Grid item key={index}>
                  <Card className={classes.root}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" style={{ backgroundColor: item.finished ? "green" : "red" }}>
                          <ShoppingCart />
                        </Avatar>
                      }
                      title={stringDate(item.date)}
                      subheader={"Precio estimado: " + formatPrice(item.amount / 100) + " - Código: " + item.code}
                    />
                    <CardActions disableSpacing className={classes.up}>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded === index,
                        })}
                        aria-expanded={expanded === index}
                        aria-label="show more"
                        onClick={() => handleExpandClick(index)}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                      <CardContent>
                        {
                          item.items.map((productData, i) => {
                            const name = productData.product.name
                            const quantity = productData.quantity
                            const measure = productData.measure.name
                            const method = productData.method.name
                            const note = productData.userNote
                            const removed = "removed" in productData
                            
                            if(removed)
                              return null;
                              
                            return (
                              <Typography paragraph key={`${index}_${i}`}>
                                - {name}: {method} ({quantity} [{measure}])<br /> {note ? "Nota: " + note : ``}
                              </Typography>
                            )

                          })
                        }

                        {!item.finished ? null : <span><b>Precio final:</b> {formatPrice(item.finalAmount / 100)}</span>}
                        <Grid item xs={12}>
                          <b>
                            Estado:
                          {
                              item.finished ?
                                <span style={{ color: "green" }}> Ya puede pasar a recoger su pedido.</span>
                                :
                                <span style={{ color: "red" }}> Aún no está listo.</span>
                            }
                          </b>
                        </Grid>
                        {
                          picture == null ? null :
                            <img src={picture} alt="factura" style={{ display: "block", width: "300px" }} />
                        }
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              )
            }
            <Grid item container justify="space-between">
              <Button variant="contained" color="secondary" onClick={onClickBack}>Regresar</Button>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div >
  )

}