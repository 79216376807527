import * as React from "react";

function SvgLmLogoIg(props) {
  return (
    <svg
      id="LM_LogoIG_svg__Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50.54 50.54"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>{".LM_LogoIG_svg__cls-2{fill:#fff}"}</style>
      </defs>
      <circle cx={25.27} cy={25.27} r={25.27} fill="#7d8123" />
      <path
        className="LM_LogoIG_svg__cls-2"
        d="M33.43 29.41a3.9 3.9 0 01-3.93 3.86h-8.45a3.9 3.9 0 01-3.94-3.86v-8.28a3.9 3.9 0 013.94-3.86h8.45a3.9 3.9 0 013.93 3.86zM29.5 15.49h-8.45a5.7 5.7 0 00-5.76 5.64v8.28a5.7 5.7 0 005.76 5.64h8.45a5.7 5.7 0 005.75-5.64v-8.28a5.7 5.7 0 00-5.75-5.64"
      />
      <path
        className="LM_LogoIG_svg__cls-2"
        d="M25.29 28.51a3.24 3.24 0 113.3-3.23 3.27 3.27 0 01-3.3 3.23m0-8.24a5 5 0 105.11 5 5.07 5.07 0 00-5.11-5M30.6 18.89a1.17 1.17 0 100 2.34 1.17 1.17 0 100-2.34"
      />
    </svg>
  );
}

export default SvgLmLogoIg;
