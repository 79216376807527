import React from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CamerasViewer from './routes/CamerasViewer'
import Cart from './routes/Cart'
import Checkout from './routes/Checkout'
import Purchases from './routes/Purchases'
import RouteNotFound from './routes/RouteNotFound'
import Login from './routes/Login/Login'
import {
  unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider, responsiveFontSizes
} from '@material-ui/core/styles'
import { CartProvider } from './functions/CartContext'
import PrivateRoute from './routes/PrivateRoute'
import Home from './routes/Home'
import { AuthProvider } from './functions/AuthContext'
import OpenStoreRoute from './routes/OpenStoreRoute'
import OpenStoreAndPrivateRoute from './routes/OpenStoreAndPrivateRoute'
import { StoreScheduleProvider } from './functions/StoreScheduleContext'
import About from './routes/About'
import FAQS from './routes/FAQS'
import Contact from './routes/Contact'
import PrivacyPolicy from './routes/PrivacyPolicy'
import Legal from './routes/Legal'
import Cookies from './routes/Cookies'
import ScrollToTop from './components/ScrollToTop'
import CookiesMessage from './components/CookiesMessage'

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8aafa9'
    },
    secondary: {
      main: '#fdd835'
    },
    text: {
      primary: '#3D3D3F',
      secondary: '#466762'
    },
    lm: {
      primary: {
        aLight: '#F5F6E3',
        aMain: '#BFC050',
        bLight: '#B0B467',
        bMain: '#7D8011'
      },
      secondary: {
        main: '#969B2D'
      },
      text: {
        black: '#3D3D3F',
        gray: '#818181'
      }
    },
  },
  typography: {
    fontFamily: [
      '"Work Sans"',
      '"Roboto"',
      '"Helvetica"',
      'sans-serif'
    ].join(',')
  }
})
theme = responsiveFontSizes(theme)

// Setup Google analytics
window.dataLayer = window.dataLayer || []
window.gtag = function gtag() { window.dataLayer.push(arguments) }
window.gtag('js', new Date())
window.gtag('config', process.env.REACT_APP_GA_TRACKING_ID)


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CartProvider>
          <AuthProvider>
            <StoreScheduleProvider>
                <Router>
                  <ScrollToTop />
                  <CookiesMessage />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/faqs" component={FAQS} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/privacy" component={PrivacyPolicy} />
                    <Route path="/legal" component={Legal} />
                    <Route path="/cookies" component={Cookies} />
                    <Route path="/login" component={Login} />
                    <OpenStoreRoute path="/viewer" component={CamerasViewer} />
                    <OpenStoreRoute path="/cart" component={Cart} />
                    <PrivateRoute path="/purchases" component={Purchases} />
                    <OpenStoreAndPrivateRoute path="/checkout" component={Checkout} />
                    <Route component={RouteNotFound} />
                  </Switch>
                </Router>
            </StoreScheduleProvider>
          </AuthProvider>
        </CartProvider>
      </ThemeProvider>
    </div >
  )
}


export default App
