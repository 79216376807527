import React, { createContext, useContext, useMemo, useReducer } from 'react'

/*
CartState structure:
{
  items: [
    {
      id,
      product: {
        _id, name, imageUrl, special_table, storeId
        price: {
          conversionFactor: {unit, value},
          selectorQuantity: {step, min},
          selectorMeasure: [{name, value}]
        },
        methods: [{name, multiplier}, ...]
        isOnCameras: []
      }, 
      method: {name, multiplier},
      measure: {name, value}, 
      quantity,
      userNote
    },
    ...
  ]
}
*/

// Use to format currency values to a string with EsEuroFormatter.format(value). 2.3 > 2,30 €
const EsEuroFormatter = new Intl.NumberFormat('es', { style: 'currency', currency: 'EUR' })

const formatPrice = (value) => EsEuroFormatter.format(value)
const formatProductPrice = (product) => EsEuroFormatter
  .formatToParts(product.price.conversionFactor.value)
  .map(({ type, value }) =>
    type === 'currency' ? product.price.conversionFactor.unit : value
  ).reduce((string, part) => string + part)

const CartContext = createContext()

function useCart() {
  return useContext(CartContext)
}

const cartInitialState = localStorage.getItem('cartCache') == null ?
  { items: [] }
  : JSON.parse(localStorage.getItem('cartCache'))

// Create new temporary item (no id) with the specified values or default from product information
function createTempItem(product, quantity, method, measure, userNote) {
  if (product) {
    const newTempItem = {
      product,
      quantity: quantity || product.price.selectorQuantity.min,
      method: method || product.methods[0],
      measure: measure || product.price.selectorMeasure[0],
      userNote: userNote || ''
    }
    return newTempItem
  } else {
    return null
  }
}

function cartReducer(state, action) {
  switch (action.type) {
    case 'addItem':
      // Set the unique id when item is added to cart
      const cartItem = {
        ...action.payload.tempItem,
        id: Date.now()
      }
      return {
        items: [...state.items, cartItem]
      }
    case 'changeItem':
      return {
        items: state.items.map(item =>
          item.id === action.payload.item.id ? (
            {
              ...item,
              method: action.payload.method,
              measure: action.payload.measure,
              quantity: action.payload.quantity,
              userNote: action.payload.userNote
            }
          ) : item)
      }
    case 'removeItem':
      return {
        items: state.items.filter(item => item.id !== action.payload.item.id)
      }
    case 'changeQuantity':
      return {
        items: state.items.map(item =>
          item.id === action.payload.item.id ? (
            {
              ...item,
              quantity: action.payload.quantity
            }
          ) : item)
      }
    case 'changeMethod':
      return {
        items: state.items.map(item => item.id === action.payload.item.id ? (
          {
            ...item,
            method: action.payload.method
          }
        ) : item)
      }
    case 'changeMeasure':
      return {
        items: state.items.map(item => item.id === action.payload.item.id ? (
          {
            ...item,
            measure: action.payload.measure
          }
        ) : item)
      }
    case 'changeUserNote':
      return {
        items: state.items.map(item => item.id === action.payload.item.id ? (
          {
            ...item,
            userNote: action.payload.userNote
          }
        ) : item)
      }
    case 'emptyCart':
      return { items: [] }
    default:
      throw new Error(`Unhandled type: ${action.type}`)
  }

}

// Get price for an item (using string to number implicit coercion with * operator)
function getItemPrice(item) {
  return (
    item.product.price.conversionFactor.value *
    item.measure.value *
    item.quantity
  )
}

function useMemoItemPriceFormatted(item) {
  return useMemo(() => {
    if (!item) return ''
    const itemPrice = getItemPrice(item)
    return formatPrice(itemPrice)
  }, [item])
}

function CartProvider({ children }) {
  const [cartState, cartDispatch] = useReducer(cartReducer, cartInitialState)
  localStorage.setItem('cartCache', JSON.stringify(cartState))

  const totalPrice = useMemo(() =>
    cartState.items.reduce((total, item) => total + getItemPrice(item), 0)
    , [cartState.items]
  )

  const numItems = useMemo(() => cartState.items.length, [cartState.items.length])

  const cart = {
    cartState,
    cartDispatch,
    createTempItem,
    getItemPrice,
    useMemoItemPriceFormatted,
    formatPrice,
    formatProductPrice,
    totalPrice,
    numItems
  }

  return (
    <CartContext.Provider value={cart}>
      {children}
    </CartContext.Provider>
  )
}

export { CartProvider, useCart }
